<script>
    import {router} from "./Router.svelte";
    import {is_function} from "svelte/internal";

    let href = "#";
    let natural = false;
    let useActive = null;
    let useExactActive = null;
    let classNames = "";
    let isAllowed = true;
    let visible = () => true;
    let title = '';
    let style = null;
    let activeClass = 'link-active';

    export {
        activeClass,
        style,
        title,
        visible,
        href,
        natural,
        useActive,
        useExactActive,
        classNames as class,
    };

    const visibilityCheck = is_function(visible) ? visible : () => visible;


    let aClass = "";

    const check = (store, callback) => {
        isAllowed = true;
        if (callback) {
            isAllowed = callback();
            if (!isAllowed) {
                return;
            }
        }

        aClass = "";

        if (useExactActive !== null && store.url == href) {
            aClass = useExactActive === true ? activeClass : useActive;
            return;
        }

        if (
            useActive !== null &&
            (store.url == href || store.url.startsWith(`${href}/`))
        ) {
            aClass = useActive === true ? activeClass : useActive;
        }
    };

    const clickHandler = (href) => (e) => {
        if (href == "" || href == "#") {
            return;
        }

        if (e.metaKey || e.ctrlKey) {
            window.open(href);
        }

        router.go(href, {preventDefault: true})();
    };

    $: check($router, visibilityCheck);
</script>

{#if isAllowed}
    {#if natural}
        <a {title} {href} {style} class="{aClass} {classNames}" on:click>
            <slot/>
        </a>
    {:else}
        <a {style}
           {title}
           {href}
           class="{aClass} {classNames}"
           on:click|preventDefault={clickHandler(href)}
           on:click
        >
            <slot/>
        </a>
    {/if}
{/if}
