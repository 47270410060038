<script>
    import IconButton from "../layout/IconButton.svelte";
    import {round} from "../../lib/lib.js";
    import {createEventDispatcher} from "svelte";

    const dispatch = createEventDispatcher();
    export let item = {
        hasQuantity: true,
        quantity: 1,
        name: '',
        price: "0"
    };

    let total;

    const toggle = () => {
        item.hasQuantity = !item.hasQuantity;

    };

    const del = () => {
        dispatch('delete', item);
    };

    $: if (item.quantity || item.price) {
        item.price = item.price.replace(',', '.');
        total = round((item.hasQuantity ? item.quantity : 1) * (+item.price)).toFixed(2);
        dispatch('change');
    }

</script>


<div class="bg-white rounded-md">
    <div class="flex items-center gap-sm text-sm px-xs">
        <div class="w-4">
            <IconButton button="minus-circle" on:click={del} color="aa0000"/>
        </div>
        <div class="flex-1">
            <input type="text" bind:value={item.name} placeholder="Όνομα">
        </div>
    </div>
    <div class="flex items-center gap-sm text-sm pb-2 mb-1 pr-sm pl-10">
        <div class="flex items-center gap-1 w-32">
            <span class="text-gray">Ποσότ.</span>
            {#if item.hasQuantity}
                <input type="text" bind:value={item.quantity} placeholder="Ποσότητα">
            {:else}
                <input type="text" bind:value={item.quantity} placeholder="Ποσότητα" disabled class="opacity-30">
            {/if}
            <div class="w-12">
                <IconButton button="check-circle" on:click={toggle} color={item.hasQuantity ? '00aa00' : 'aaaaaa'}
                            size={18}/>
            </div>
        </div>

        <div class="flex items-center gap-1 w-24">
            <span class="text-gray">Τιμή</span>
            <input type="text" bind:value={item.price} placeholder="Τιμή">€
        </div>
        <div class="text-right flex-1">
            {total}€
        </div>
    </div>
</div>