<script>
    import {API, venues$} from "../../lib/lib.js";
    import ErrorDisplay from "../layout/ErrorDisplay.svelte";
    import {focusFirstElement, trapKey} from "../../lib/actions.js";
    import {createEventDispatcher} from "svelte";
    import Spacer from "../layout/Spacer.svelte";
    import PairEditor from "../varius/PairEditor.svelte";
    import Icon from "../layout/Icon.svelte";
    import IconButton from "../layout/IconButton.svelte";

    export let venue = {};


    const dispatch = createEventDispatcher();

    let errors = null;
    let contractExpanded = true;
    let taeExpanded = false;

    const venueCopy = {
        name: venue.name || '',
        code: venue.code || '',
        gutter_color: venue.gutter_color || '#fed820',
        incharge: venue.incharge || '',
        phones: venue.phones || '',
        contract: venue.contract || {},
        tae: venue.tae || {},
    };

    const resetVenueForm = () => {
        venueCopy.name = '';
        venueCopy.code = '';
        venueCopy.gutter_color = '#fed820';
        venueCopy.incharge = '';
        venueCopy.phones = '';
        venueCopy.contract = {};
        venueCopy.tae = {};
    };

    const saveVenue = () => {
        errors = null;

        if (venue.id) {
            updateVenue();
        } else {
            createNewVenue();
        }
    };

    const createNewVenue = async () => {
        const res = await API.post(`venue`, venueCopy);

        if (res.error == true) {
            errors = res.data;
            return;
        }

        venues$.update(store => {
            return [...store, res.data];
        });

        resetVenueForm();

        showMessage('Η εγκατάσταση δημιουργήθηκε');
        dispatch('save', res.data);
    };

    const updateVenue = async () => {
        const res = await API.patch(`venue/${venue.id}`, venueCopy);
        if (res.error == true) {
            errors = res.data;
            return;
        }

        venues$.update(store => {
            return store.map(v => {
                if (v.id == venue.id) {
                    return Object.assign(v, venueCopy)
                }
                return v;
            });
        });

        showMessage('Η εγκατάσταση αποθηκεύτηκε');
        dispatch('save', res.data);
    };

    const updateSomeTitles = () => {
        if (!venueCopy.tae?.title || venueCopy.tae.title.trim().length == 0) {
            venueCopy.tae.title = venueCopy.name
        }
        if (!venueCopy.contract?.company || venueCopy.contract.company.trim().length == 0) {
            venueCopy.contract.company = venueCopy.name
        }
    };


</script>

<div class="overflow-y-auto max-h-[560px] text-sm" use:focusFirstElement>
    <PairEditor title="Όνομα">
        <input type="text" bind:value={venueCopy.name} use:trapKey={{callback: saveVenue}} on:blur={updateSomeTitles}>
    </PairEditor>
    <PairEditor title="Κωδικός">
        <input type="text" bind:value={venueCopy.code} use:trapKey={{callback: saveVenue}}>
    </PairEditor>

    <PairEditor title="Υπεύθυνος">
        <input type="text" bind:value={venueCopy.incharge} use:trapKey={{callback: saveVenue}}>
    </PairEditor>
    <PairEditor title="Τηλέφωνα">
        <input type="text" bind:value={venueCopy.phones} use:trapKey={{callback: saveVenue}}>
    </PairEditor>

    <PairEditor title="Χρώμα">
        <input type="color" bind:value={venueCopy.gutter_color}>
    </PairEditor>

    <div class="border border-zinc-300">
        <div class="text-gray border-b border-b-zinc-100 bg-white text-sm flex items-center gap-sm p-1"
             on:click={() => contractExpanded = !contractExpanded}>

            {#if contractExpanded}
                <Icon icon="arrow-up-drop-circle-outline"/>
            {:else}
                <Icon icon="arrow-down-drop-circle-outline"/>
            {/if}
            <span class="flex-1">Στοιχεία συμβολαίου</span>
        </div>
        {#if contractExpanded}
            <div class="p-sm bg-zinc-50 shadow-inner">
                <div class="font-thin text-sm">Κεφαλίδα</div>
                <div class="text-xs">
                    <textarea class="mb-2 h-32" bind:value={venueCopy.contract.header}/>
                </div>

                <PairEditor title="Τοποθεσία συμβολαίου">
                    <input type="text"
                           bind:value={venueCopy.contract.place}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Εταιρία">
                    <input type="text"
                           bind:value={venueCopy.contract.company}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Όνομα">
                    <input type="text"
                           bind:value={venueCopy.contract.name}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Α.Δ.Τ.">
                    <input type="text"
                           bind:value={venueCopy.contract.adt}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Όνομα πατρός (του)">
                    <input type="text"
                           bind:value={venueCopy.contract.father_name}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Όνομα μητρός (της)">
                    <input type="text"
                           bind:value={venueCopy.contract.mother_name}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="ΑΦΜ">
                    <input type="text"
                           bind:value={venueCopy.contract.afm}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="ΔΟΥ">
                    <input type="text"
                           bind:value={venueCopy.contract.doy}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Διεύθυνση">
                    <input type="text"
                           bind:value={venueCopy.contract.address}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>
            </div>
        {/if}

    </div>

    <div class="border border-zinc-300 mt-2">
        <div class="text-gray border-b border-b-zinc-100 bg-white text-sm flex items-center gap-sm p-1">
            <button on:click={() => taeExpanded = !taeExpanded}>
                {#if taeExpanded}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            <span class="flex-1">Στοιχεία T.A.E.</span>
        </div>

        {#if taeExpanded}
            <div class="p-sm bg-zinc-50 shadow-inner">

                <div class="mb-2 text-sm">
                    <div class="font-thin text-sm">Παραλήπτης εγγράφου</div>
                    <textarea class="text-xs h-32" bind:value={venueCopy.tae.police}/>
                </div>

                <PairEditor title="Τίτλος καταστήματος">
                    <input type="text"
                           bind:value={venueCopy.tae.title}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Δ/νση καταστήματος">
                    <input type="text"
                           bind:value={venueCopy.tae.address}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

                <PairEditor title="Ιδιοκτήτης καταστήματος">
                    <input type="text"
                           bind:value={venueCopy.tae.owner}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>
                <PairEditor title="Τρ. Προμήθειας">
                    <input type="text"
                           bind:value={venueCopy.tae.merchant}
                           use:trapKey={{callback: saveVenue}}>
                </PairEditor>

            </div>
        {/if}
    </div>
</div>
<ErrorDisplay bind:errors/>
<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveVenue}>OK</button>
</div>
