<script>
    import CalendarDayEventItem from './CalendarDayEventItem.svelte';
    import {nameOfDays} from '../../lib/lib.js';
    import IconButton from '../layout/IconButton.svelte';
    import A from '../../lib/Router/A.svelte';

    export let events;
    export let day;
    export let date;

    export let isList = false;
    const now = new Date();
    const names = nameOfDays();
    names.push(names.shift());

    let theDate;


    let dayNumber;
    let isWE;
    let isPast;


    $: {
        theDate = new Date(date.getTime());
        theDate.setDate(day);
        dayNumber = theDate.getDay() - 1;
        if (dayNumber == -1) {
            dayNumber = 6;
        }
        isWE = theDate.getDay() == 0 || theDate.getDay() == 6;
        isPast = +now > 86_400_000 + (+theDate);
    }

</script>


<div class="mb-[2px] sm:mb-1 border border-zinc-100 text-xs" class:min-h-32={!isList}
     class:bg-zinc-200={isWE}
     class:bg-zinc-50={!isWE}
     class:!bg-gray-light={isPast}>

    <div class="p-1 flex dayItem h-8">
        <div class="flex-1 ">
            {#if isList}
                {names[dayNumber]}
            {/if}
            {day}
        </div>
        {#if !isPast}
            <div class="hidden day-plus">
                <A href="/app/new-event/{theDate.toISOString().split('T')[0]}">
                    <IconButton button="plus-circle" color="00aa00"></IconButton>
                </A>
            </div>
        {/if}
    </div>

    <div>
        {#if events}
            {#each events as event}
                <CalendarDayEventItem
                        on:quickView
                        {isPast}
                        {isList}
                        {event}/>
            {/each}
        {/if}
    </div>
</div>


<style>
    .day-plus {
        display: none;
    }

    .dayItem:hover .day-plus {
        display: block;
    }
</style>