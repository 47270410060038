<script>
    import IconButton from '../layout/IconButton.svelte';
    import {subMonths} from 'date-fns/fp';
    import {nameOfMonths} from '../../lib/lib.js';
    import {navigate} from '../../lib/Router/Router.svelte';


    export let month;
    export let year;

    let now = new Date();
    let all = [];

    let selectedDate = `${year}-${Number(month)}`;

    // dispatch('change', {month: m, year: y});

    const monthNames = nameOfMonths;

    const prevMonth = e => {
        const idx = all.findIndex(d => d.key == selectedDate);
        let dt = all[idx - 1];
        if (dt.text == '-') {
            if(idx == 1) {
                selectDate({
                    key: `${Number(selectedDate.split('-')[0]) - 1}-12`
                });
                return;
            }
        }

        selectDate(dt);
    };

    const nextMonth = e => {
        const idx = all.findIndex(d => d.key == selectedDate);
        let dt = all[idx + 1];
        if (dt.text == '-') {
            dt = all[idx + 2];
        }
        selectDate(dt);
    };

    const selectDate = dt => {
        let [year, month] = dt.key.split('-');

        selectedDate = `${year}-${month}`;
        createDates(month, year);
        navigate(`/app/events/${year}-${month}`);
    };

    const createDates = (m, y) => {
        dd("Creatingg dates for ", m, y)
        y = Number(y);
        all = [];
        [ 0, 1, 2].map(yy => {
            const year = y + yy;
            all.push({key: year, text: '-'});
            Array(12).fill('').map((__, idx) => {
                all.push({
                    key: `${year}-${idx + 1}`,
                    text: ` - ${monthNames[idx]} (${year})`
                });
            });
        });
    };

    const createDatesOld = (m, y) => {
        now = new Date();
        now.setMonth(m - 1);
        now.setFullYear(y);

        let count = -40;
        all = [];

        while (count < 15) {
            let d = subMonths(count);
            let da = d(now);
            all = [[da.getFullYear(), (1 + da.getMonth())], ...all];
            count++;
        }
        all = all;
    };

    const changeDate = e => {
        let [year, month] = selectedDate.split('-');
        navigate(`/app/events/${year}-${month}`);
    };

    $: createDates(month, year);
</script>


<div class="flex items-center gap-sm bg-white -px-lg">
    <IconButton on:click={prevMonth} button="chevron-left-circle"/>
    <select class="w-2/3" bind:value={selectedDate} on:change={changeDate}>
        {#each all as item}
            {#if (item.text === '-')}
                <optgroup label={item.key}></optgroup>
            {:else }
                <option value={item.key}>
                    {item.text}
                </option>
            {/if}
        {/each}
    </select>
    <IconButton on:click={nextMonth} button="chevron-right-circle"/>
</div>