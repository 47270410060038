<script>
    import IconButton from '../../layout/IconButton.svelte';
    import {API} from '../../../lib/lib.js';
    import {createEventDispatcher} from 'svelte';

    export let note;
    export let entity;
    export let endpoint = 'event';

    let isEditing = false;

    const dispatch = createEventDispatcher();

    note.id = note.id || 0;
    note.body = note.body || '';

    let initialBody = note.body;
    let isDirty = false;

    const saveNote = async () => {
        const res = await API.patch(`${endpoint}/${entity.id}/note/${note.id}`, {body: note.body});

        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        entity.notes.map(n => {
            if (n.id == note.id) {
                n.body = note.body;
            }
        });

        showMessage('Η σημείωση ενημερώθηκε');
        initialBody = note.body;
    };

    const deleteNote = async () => {
        if (!note.id) {
            entity.notes = entity.notes.filter(n => n != note);
            showMessage('Η σημείωση διαγράφηκε');
            dispatch('delete');
            return;
        }
        if (confirm('Είσαστε σίγουροι')) {
            const res = await API.delete(`${endpoint}/${entity.id}/note/${note.id}`);
            if (res.error) {
                showMessage('Κάτι δεν πήγε καλά', 'bg-red');
                return;
            }

            entity.notes = entity.notes.filter(n => n.id != note.id);
            showMessage('Η σημείωση διαγράφηκε');
            dispatch('delete');
        }
    };

    $: isDirty = (note.body != initialBody);
</script>


{#if isEditing}
    <div class="flex items-start gap-sm p-sm mb-1 text-sm bg-bg my-1">
        <IconButton color="ff0000" button="minus-circle" on:click={deleteNote}/>
        <textarea bind:value={note.body} class:!bg-yellow={isDirty}></textarea>
        <div>
            <IconButton color="00aa00" button="save" on:click={saveNote}/>
            <br>
            <IconButton color="aa0000" button="back" on:click={() => isEditing = false}/>
        </div>
    </div>
{:else}
    <div class="flex items-start gap-sm p-sm mb-1 text-sm bg-bg my-1">
        <div class="flex-1">{note.body}</div>
        <IconButton color="00aa00" button="edit" on:click={() => isEditing = true}/>
    </div>
{/if}