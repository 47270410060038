<script>
    import {API, dDMYhm, EVENT_STATUS} from "../../../lib/lib.js";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import EventContractData from "../../papers/EventContractData.svelte";

    export let event;
    export let venue;

    let popupVisible = false;

    const showEventContractPopup = () => popupVisible = true;
    const hideEventContractPopup = () => popupVisible = false;

    const toggleAndPrintContract = async e => {
        await toggleContractPrint(true);
        window.open(`/print/${event.id}/contract`);
    };

    const toggleContractPrint = async e => {
        if (!confirm("Το συμφωνητικό θα αλλάξει κατάσταση εκτύπωσης.\n\nΕίσαστε σίγουροι;")) {
            return;
        }

        let toSend = {};
        if (e === true) {
            toSend = {state: true}
        }

        const res = await API.patch(`event/${event.id}/contract`, toSend);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.contract.printed_at = res.data.printed_at;

        showMessage(`Η κατάσταση άλλαξε σε εκτυπωμένο`);
    };
</script>

<div class="mb-2">
    <div class="font-bold">

        {#if event.status != EVENT_STATUS.EVENT_STATUS_CANCELED}
            <span class="underline link" on:click={showEventContractPopup}>Συμφωνητικό</span>
        {:else}
            Συμφωνητικό
        {/if}
    </div>

    <div class="p-1 mb-1">
        {#if event.contract?.printed_at}
            <span class="text-green">Εκτυπώθηκε: {dDMYhm(event.contract.printed_at)}</span>
            <span class="link" on:click={toggleAndPrintContract}>Εκτύπωση</span>
        {:else}
            {#if event.contract == null}
                <span class="text-red">Δεν έχει δημιουργηθεί συμφωνητικό.</span>
            {:else}
                Δεν εχει εκτυπωθεί συμφωνητικό.
                <span class="link" on:click={toggleAndPrintContract}>Εκτύπωση</span>
            {/if}
        {/if}


    </div>
</div>


{#if popupVisible}
    <ScreenCenter title="Ορισμός συμφωνητικού" on:close={hideEventContractPopup}>
        <EventContractData
                {event}
                {venue}
                on:close={hideEventContractPopup}
        />
    </ScreenCenter>
{/if}
