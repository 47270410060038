<script>
    import {createEventDispatcher} from "svelte";
    import SquareButton from "../layout/SquareButton.svelte";
    import Icon from "../layout/Icon.svelte";

    export let eventType;

    const dispatch = createEventDispatcher();

    const edit = () => dispatch('edit', eventType);
</script>


<div class="sre-item bg-bg">
    <div class="border-b border-b-gray-light p-sm flex items-center gap-sm">
        <div class="flex-1 flex gap-sm">
            {eventType.name}
        </div>
        <button on:click={edit}>
            <Icon icon="pencil-circle" color="000000"/>
        </button>
    </div>
</div>
