<script>
    import {createEventDispatcher, onMount} from "svelte";
    import {copyObj} from "../../lib/lib.js";

    export let data;
    export let checked;

    let myData = copyObj(data);

    const dispatch = createEventDispatcher();

    let send = false;
    onMount(() => send = true);

    $: if (send) {
        dispatch('toggle', {data: myData, checked})
    }
</script>

<div class="flex items-center gap-sm mb-2">
    <input type="checkbox" bind:checked>
    <div class="flex-1 leading-tight" class:text-gray={!checked}>
        {myData.text}
    </div>
    <div class="w-12">
        {#if myData.default != ''}
            <input type="text" bind:value={myData.default}/>
        {/if}
    </div>
</div>