<script>
    import A from "../../lib/Router/A.svelte";

    export let bg = '#ffffff';
    export let color = 'currentColor';
    export let button = 'pencil';
    export let href = null;
    export let title = '';
    export let size = 22;

    if (button == 'edit') button = 'pencil';
</script>

{#if href}
    <A {title} class="no-focus-ring p-1 cursor-pointer inline-block" style="width:{size}px; background-color: {bg}" {href}>
        <img src="https://icongr.am/material/{button}.svg?size={size}&color={color}" alt="" style="width:{size}px;">
    </A>
{:else}
    <button {title} class="no-focus-ring cursor-pointer" on:click style="width:{size}px; background-color: {bg}">
        <img src="https://icongr.am/material/{button}.svg?size={size}&color={color}" alt="" style="width:{size}px;">
    </button>
{/if}

<style>
    img {
        height: 100%;
    }
</style>