<script>
    import Icon from "../layout/Icon.svelte";
    import {createEventDispatcher} from "svelte";
    import ServiceItem from "./ServiceItem.svelte";

    export let category;

    export let expanded = false;

    const dispatch = createEventDispatcher();

    const edit = () => dispatch('edit', category);
    const addService = () => dispatch('addService', category);
    const toggle = () => expanded = !expanded;
    const del = () => dispatch('delete', category);
</script>

<div class="sre-item mb-2 shadow border-t border-t-gray-light bg-bg">
    <div class="border-b border-b-gray-light p-sm flex items-center gap-1">
        <button class="plain" on:click={toggle}>
            {#if expanded}
                <Icon icon="arrow-up-drop-circle-outline"/>
            {:else}
                <Icon icon="arrow-down-drop-circle-outline"/>
            {/if}
        </button>
        <div class="flex-1 flex gap-sm" class:text-gray={!category.is_active}>
            {category.name} ({category.services.length} services)
        </div>

        <button class="plain" on:click={edit}>
            <Icon icon="pencil-circle" color="000000"/>
        </button>
    </div>

    {#if expanded}
        <div>
            {#if category.services.length > 0}
                {#each category.services as service}
                    <ServiceItem
                            on:editService
                            on:deleteService
                            {service}
                            {category}
                    />
                {/each}
            {/if}
        </div>

        <div class="p-1 pl-lg pr-sm bg-white flex items-center gap-sm">
            <button class="plain flex-1" on:click={del}>
                <Icon icon="trash-can" color="ff0000"/>
            </button>
            <button class="plain" on:click={addService}>
                <Icon color="00aa00" icon="plus-circle"/>
            </button>
        </div>
    {/if}
</div>