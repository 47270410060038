<script>
    import A from "../../lib/Router/A.svelte";

    export let url = '';
</script>


<A useExactActive href="/app{url ? `/${url}` : '' }" on:click class="p-sm bg-zinc-500 border-b hover:bg-orange block text-white no-underline text-lg">
    <slot/>
</A>
