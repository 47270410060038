<script>
    import Icon from "../../layout/Icon.svelte";
    import {createEventDispatcher} from "svelte";

    export let service;
    export let allServices;

    let checked = allServices.includes(service.id);
    const dispatch = createEventDispatcher();
    const toggle = () => dispatch('toggle', service);
</script>

<div class="plain py-1 w-full text-left px-lg border-t flex md:flex-row-reverse gap-sm items-center cursor-pointer"
     class:bg-amber-50={checked}
     on:click|stopPropagation={toggle}>
    <div class="flex-1 text-sm" class:text-gray={!checked}
         class:text-red={!service.is_active}
    >
        {@html service.name}
    </div>
    <Icon icon="check" color={ checked ? '00aa00' : 'bbbbbb' }/>
</div>