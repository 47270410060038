<script>
    import Icon from "../layout/Icon.svelte";
    import {createEventDispatcher} from "svelte";

    export let data;

    const dispatch = createEventDispatcher();
    const edit = () => dispatch('edit', data);
</script>

<div class="sre-item bg-bg">
    <div class="border-b border-b-gray-light p-sm flex items-center gap-sm">
        <div class="flex-1 flex gap-sm">
            <div class="flex-1 font-thin">{data.name}</div>
        </div>
        <button on:click={edit}>
            <Icon icon="pencil-circle" color="000000"/>
        </button>
    </div>
</div>
