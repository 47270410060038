<script>
    import Icon from "../layout/Icon.svelte";
    import IconButton from "../layout/IconButton.svelte";

    export let service;

    let color;
    let button;

    const toggle = () => {
        service.selected = !service.selected;
    }

    $: color = service.selected ? '009900' : 'cccccc';
    $: button = service.selected ? 'check-circle' : 'circle-outline';
</script>

<label class="py-1 flex items-center gap-sm">
    <button class="plain" on:click={toggle}>
        <IconButton {button} {color}/>
    </button>
    <input
            type="text"
            disabled={!service.selected}
            class:text-gray-light={!service.selected}
            class="flex-1"
            bind:value={service.name}/>
    {#if service.price}
        <input
                type="text"
                disabled={!service.selected}
                class:text-gray-light={!service.selected}
                class="w-12"
                bind:value={service.price}/>
    {/if}
</label>