<script>
    import {API, billables$, copyObj} from "../../../lib/lib.js";
    import EventBillableItem from "../../billable/EventBillableItem.svelte";
    import IconButton from "../../layout/IconButton.svelte";
    import {createEventDispatcher} from "svelte";
    import PairEditor from "../../varius/PairEditor.svelte";

    export let event;

    const dispatch = createEventDispatcher();

    let billables = [];
    let total = 0;
    let paymentTotal = (event.payments || []).reduce((acc, payment) => acc + (+payment.amount), 0);
    let paymentData = {
        is_paid: false,
        description: 'Εξώφληση'
    };

    if (event.billables) {
        billables = copyObj(event.billables);
    } else {
        (event.contract.services || []).map(serv => {
            if (serv.selected) {
                billables.push({
                    cat_id: '0',
                    hasQuantity: (serv.price ? true : false),
                    quantity: 1,
                    name: serv.name,
                    price: serv.price
                });
            }
        });
    }


    const add = id => () => {
        billables.push({
            cat_id: `${id}`,
            hasQuantity: true,
            quantity: 1,
            name: '',
            price: "0"
        });
        billables = billables;
    };

    const delItem = ({detail: item}) => {
        billables = billables.filter(i => i != item);
        billables = billables;

    };

    const saveBillables = async () => {
        let finalBill = [];

        let hasError = [];
        if (billables.length == 0) {
            showMessage('Δεν μπορεί να γίνει εκκαθάριση χωρίς κάτι χρεώσιμο', 'bg-red');
            return;
        }
        let hasTitleError = false;
        billables.map(billable => {
            const price = Number(billable.price);
            if (billable.name.length < 1) {
                hasTitleError = true;
                return;
            }
            if (!price || (price && !billable.quantity)) {
                hasError.push(billable.name);
            } else {
                finalBill.push({
                    cat_id: billable.cat_id,
                    hasQuantity: billable.hasQuantity,
                    quantity: billable.quantity,
                    name: billable.name,
                    price: billable.price,
                });
            }
        });

        if (hasTitleError) {
            showMessage('Κάποιο από τα στοιχεία δεν έχει τίτλο.<br>H καταχώρηση δεν μπορεί να γίνει', 'bg-red');
            return;
        }


        if (paymentData.is_paid && paymentData.contact_id == 0) {
            showMessage('θα πρέπει να επιλέξετε επαφή για την πληρωμή', 'bg-red');
            return;
        }

        if ((hasError.length > 0) && !confirm('Τα παρακάτω στοιχεία δεν έχουν τιμές ή ποσότητες ή τίτλο, και ΔΕΝ θα καταχωρηθούν. Είσαστε σίγουροι ότι θέλετε να συνεχίσετε;\n\n -' + hasError.join('\n -'))) {

            return;
        } else {
            if (!confirm('Είσαστε σίγουροι ότι θέλετε να καταχωρήσετε εκκαθάριση για το event;')) {
                return;
            }
        }

        const res = await API.post(`event/${event.id}/bill`, {
            billables,
            paymentData
        });
        if (res.error) {
            showMessage('Κάποιο λάθος συνέβη', 'bg-red');
            return;
        }

        dispatch('reload');
    };

    const calcTotal = b => {
        total = b.reduce((acc, item) => {
            let price = item.price.replace(',', '.');
            return acc + (item.hasQuantity ? (+item.quantity * +price) : +price)
        }, 0);
        paymentData.amount = total - paymentTotal;
    };
    const calcItem = event => calcTotal(billables);

    $: calcTotal(billables);
</script>


<div class="max-h-[460px] overflow-y-auto">
    <div class="mb-md">
        <div class="flex items-center gap-sm p-1 border border-zinc-300 bg-zinc-200 mb-1">
            <div class="text-md flex-1">Γενικά</div>
            <IconButton button="plus-circle" on:click={add(0)} color="00aa00"/>
        </div>
        {#each billables as item}
            {#if item.cat_id == '0'}
                <EventBillableItem on:delete={delItem} {item} on:change={calcItem}/>
            {/if}
        {/each}
    </div>

    {#each $billables$ as {name, id}}
        <div class="mb-sm">
            <div class="flex items-center gap-sm p-1 border border-zinc-300 bg-zinc-200 mb-1">
                <div class="text-md flex-1">{name}</div>
                <IconButton button="plus-circle" on:click={add(id)} color="00aa00"/>
            </div>

            {#each (billables || []) as item}
                {#if item.cat_id == id}
                    <EventBillableItem on:delete={delItem} {item} on:change={calcItem}/>
                {/if}
            {/each}
        </div>
    {/each}
    <div class="p-2 bg-yellow grid grid-cols-3 text-sm">
        <div>
            <div class="font-bold">Σύνολο:</div>
            {total}€
        </div>
        <div class="text-center">
            <div class="font-bold">{event.payments.length} {event.payments.length == 1 ? 'Πληρωμή' : 'Πληρωμές'}:
            </div>
            {paymentTotal}€
        </div>
        <div class="text-right">
            <div class="font-bold">ΥΠΟΛΟΙΠΟ:</div>
            {total - paymentTotal}€
        </div>
    </div>

    <div class="my-sm py-xs p-2 text-sm">
        <label class="cursor-pointer">
            <input type="checkbox" bind:checked={paymentData.is_paid}> Καταχώρηση πληρωμής
        </label>

        {#if paymentData.is_paid}
            <div class="mb-2 pt-1 px-sm text-sm">
                <PairEditor title="Από">
                    <select bind:value={paymentData.contact_id}>
                        <option value="0">Επιλέξτε επαφή</option>
                        {#each event.contacts as contact}
                            <option value={contact.id}>
                                {`${contact.first_name} ${contact.last_name}`}
                            </option>
                        {/each}
                    </select>
                </PairEditor>

                <PairEditor title="Ποσό πληρωμής">
                    <input type="text" bind:value={paymentData.amount}/>
                </PairEditor>
                <PairEditor title="Αιτιολογία">
                    <input type="text" bind:value={paymentData.description}/>
                </PairEditor>
            </div>
        {/if}
    </div>
</div>

{#if paymentData.amount < 0}
    <div class="text-center text-white bg-red rounded py-2">ΠΡΟΣΟΧΗ! Αρνητικό υπόλοιπο!</div>
{/if}
<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveBillables} disabled={billables.length == 0}
            class:!bg-gray-light={billables.length == 0}>Αποθήκευση
    </button>
</div>
