<script>
    import {API, copyObj, dDMY, EVENT_STATUS, eventTypes$, findItem, venues$} from '../../lib/lib.js';
    import Spacer from '../layout/Spacer.svelte';
    import IconButton from '../layout/IconButton.svelte';
    import NoteItem from './qv/NoteItem.svelte';
    import ContactItem from './qv/ContactItem.svelte';
    import ScreenCenter from '../ScreenCenter.svelte';
    import EventDateSelector from './EventDateSelector.svelte';
    import {createEventDispatcher} from 'svelte';
    import {navigate, router} from '../../lib/Router/Router.svelte';
    import Icon from '../layout/Icon.svelte';
    import {showContactsPanel} from '../contacts/ContactsPanel.svelte';
    import ContractData from '../papers/ContractData.svelte';
    import EventProsxedio from './qv/EventProsxedio.svelte';
    import EventServices from './qv/EventServices.svelte';
    import EventContract from './qv/EventContract.svelte';
    import EventTae from './qv/EventTae.svelte';
    import {isPast, parseISO} from 'date-fns';
    import QvPaymentItem from './qv/QvPaymentItem.svelte';
    import PaymentEditor from './qv/PaymentEditor.svelte';
    import EventDone from './qv/EventDone.svelte';

    export let id;

    let event = null;
    let venue;
    let room;

    let cnt = 0;

    let notesExpanded = false;
    let detailsExpanded = true;

    let moneyExpanded = false;

    let loaded = false;
    let selectedDate = null;
    let selectedPayment = null;

    let addDatePopupVisible = false;
    let addPaymentPopupVisible = false;
    let showCompletePopupVisible = false;
    let contractPopupVisible = false;
    let showPaymentBeforeFixing = false;

    const dispatch = createEventDispatcher();

    const redraw = () => cnt++;

    const showCompleteEventPopup = () => {
        if (!event.contract) {
            showMessage('Θα πρέπει να ολοκληρώσετε το συμφωνητικό προκειμένου\nνα μπορέσετε να κάνετε ολοκλήρωση/εκκαθάριση', 'bg-red');
            return;
        }
        showCompletePopupVisible = true;
    };

    const hideCompleteEventPopup = () => showCompletePopupVisible = false;

    const addNote = () => {
        event.notes = [...event.notes, {id: 0, body: ''}];
    };

    const showAddPaymentDialog = () => {
        addPaymentPopupVisible = true;
    };

    const editPayment = payment => () => {
        if (event.paid_at) {
            return;
        }

        selectedPayment = payment;
        addPaymentPopupVisible = true;
    };

    const hideAddPaymentDialog = ({detail}) => {
        selectedPayment = null;
        addPaymentPopupVisible = false;
        if (showPaymentBeforeFixing) {
            showPaymentBeforeFixing = false;
            if (detail.fix) {
                doFixDate(detail.fix);
            }
        }
        redraw();
    };

    const showAddDateDialog = () => {
        addDatePopupVisible = true;
    };

    const hideAddDateDialog = () => {
        selectedDate = null;
        addDatePopupVisible = false;
    };

    const addDate = async ({detail: spec}) => {
        if (event.dates.filter(d => d.date_at == spec.date_at && d.hour == spec.hour).length > 0) {
            showMessage('Η ημερομηνία αυτή έχει επιλεγεί ήδη');
            return;
        }
        const res = await API.post(`event/${event.id}/date`, spec);

        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.dates = [...event.dates, res.data];
        showMessage('Η ημερομηνία προστέθηκε');

        const newEvent = copyObj(event);
        newEvent.date_at = spec.date_at;
        newEvent.hour = spec.hour;
        dispatch('event:change', {action: 'add', date: spec.date_at, event: newEvent});

        hideAddDateDialog();
    };

    const deleteDate = async date => {
        if (!confirm('Είσαστε σίγουροι')) {
            return;
        }

        const res = await API.delete(`event/${event.id}/date/${date.id}`);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.dates = event.dates.filter(dt => dt.id != date.id);
        showMessage('Η διαγραφή της ημερομηνίας ολοκληρώθηκε');
        dispatch('event:change', {action: 'delete', event_id: event.id, date: date.date_at});
    };

    const fixDate = async (date, past) => {
        let message;
        if (past) {
            message = `ΠΡΟΣΟΧΗ!!!!!!! Η ΗΜΕΡΟΜΗΝΙΑ ${date.date_at.split('-').reverse().join('/')} , ώρα ${date.hour} ΕΙΝΑΙ ΣΤΟ ΠΑΡΕΛΘΟΝ. \n\nΕίσαστε σίγουροι;`;
            if (!confirm(message)) {
                return;
            }
        }
        showPaymentBeforeFixing = date;
        showAddPaymentDialog();
    };

    const doFixDate = async (date) => {
        const res = await API.patch(`event/${event.id}/date/${date.id}`);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.status = EVENT_STATUS.EVENT_STATUS_APPROVED;
        event.approved_at = date.date_at;
        date.is_confirmed = true;

        showMessage('Η οριστικοποίηση έγινε!');

        dispatch('event:change', {action: 'fix', event_id: event.id, date: date.date_at});
    };

    const unFix = async date => {
        if (!confirm('Η εκδήλωση θα αλλάξει κατάσταση σε ΝΕΑ προκειμένου να αλλάξετε ημερομηνία.\n\nΕίσαστε σίγουροι;')) {
            return;
        }

        const res = await API.patch(`event/${event.id}/unfix`);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        markAsNew();
        showMessage('Το event είναι ΝΕΟ.');

        dispatch('event:change', {action: 'unfix', event_id: event.id, date: date.date_at});
    };

    const markAsNew = () => {
        event.status = EVENT_STATUS.EVENT_STATUS_NEW;
        event.approved_at = null;
        event.completed_at = null;
        event.dates.map(d => d.is_confirmed = false);
    };

    const cancelEvent = async e => {
        if (!confirm('Η εκδήλωση θα αλλάξει κατάσταση σε ΑΚΥΡΩΜΕΝΗ.\n\nΕίσαστε σίγουροι;')) {
            return;
        }

        const res = await API.delete(`event/${event.id}/cancel`);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.status = EVENT_STATUS.EVENT_STATUS_CANCELED;
        event.approved_at = null;
        event.completed_at = null;
        event.dates.map(d => d.is_confirmed = false);

        showMessage('To event ακυρώθηκε');
        dispatch('event:change', {
            action: 'cancel',
            event_id: event.id,
            date: event.date_at,
            cancel_time: res.data.canceled_at
        });
    };

    const unCancelEvent = async e => {
        if (!confirm('Η εκδήλωση θα αλλάξει κατάσταση σε ΝΕΑ προκειμένου να ενεργοποιηθεί.\n\nΕίσαστε σίγουροι;')) {
            return;
        }

        const res = await API.patch(`event/${event.id}/uncancel`);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        markAsNew();

        dispatch('event:change', {action: 'uncancel', event_id: event.id, date: event.date_at});

        showMessage('Το event είναι ΝΕΟ.');
    };

    const showAddContactDialog = () => {
        showContactsPanel(onSelectContact);
    };

    const onSelectContact = async contact => {
        if (event.contacts.filter(c => c.id == contact.id).length > 0) {
            showMessage('Έχετε ήδη επιλέξει αυτή την επαφή', 'bg-red');
            return false;
        }
        contact.pivot = contact.pivot || {title: ''};

        const res = await API.patch(`event/${event.id}/contact/${contact.id}`, {title: contact.pivot.title});
        event.contacts = [...event.contacts, copyObj(contact)];
        return true;
    };

    const removeContact = ({detail: contact}) => {
        event.contacts = event.contacts.filter(c => c != contact);
    };

    const showContractPopup = () => {
        contractPopupVisible = true;
    };

    const hideContractPopup = () => {
        contractPopupVisible = false;
    };

    const toggleAndPrintContract = async e => {
        //
    };

    const toggleContractPrint = async e => {
        //
    };

    const saveContactData = async ({detail: data}) => {

    };


    const init = async () => {
        loaded = false;
        const res = await API.get(`event/${id}`);

        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event = res.data;
        venue = findItem(event.venue_id, $venues$);
        room = findItem(event.room_id, venue.rooms);

        loaded = true;
    };
    const closeDetailsPopup = () => {
        if (document.location.href.includes('?')) {
            navigate($router.url);
        } else {
            dispatch('close');
        }
    };

    init();
</script>


{#if loaded}
    {#key cnt}
        <div class="ev-{event.status} p-sm py-1 -m-4 shadow-md font-thin text-center mb-md">
            {findItem(event.event_type_id, $eventTypes$).name} <span class="text-xs">({event.guests_nr} άτομα)</span>
        </div>

        <div class="grid-cols-2 grid bg-white pb-1 font-thin">
            <div class="text-right px-1" style="border-top:10px solid {venue.gutter_color}">
                {venue.name}
            </div>
            <div class="px-1" style="border-top:10px solid {room.gutter_color}">
                {room.name}
            </div>
        </div>

        <Spacer size="xs"/>
        <div class="text-gray text-sm flex items-center gap-sm p-1">
            Επαφές
            {#if event.status != EVENT_STATUS.EVENT_STATUS_COMPLETED}
                <IconButton title="Προσθήκη επαφής" color="00aa00" button="plus-circle"
                            on:click={showAddContactDialog}/>
            {/if}
        </div>
        <div>
            {#each event.contacts as contact}
                <ContactItem
                        readOnly={event.status == EVENT_STATUS.EVENT_STATUS_COMPLETED}
                        on:delete={redraw}
                        {contact}
                        {event}
                />
            {/each}
        </div>

        <Spacer size="xs"/>
        <div class="text-gray text-sm flex items-center gap-sm p-1">
            Ημερομηνίες
            {#if event.status == EVENT_STATUS.EVENT_STATUS_NEW}
                <IconButton title="Προσθήκη πιθανής ημερομηνίας" color="00aa00" button="plus-circle"
                            on:click={showAddDateDialog}/>
            {/if}
        </div>
        <div class="text-sm">
            {#if event.status == EVENT_STATUS.EVENT_STATUS_APPROVED}
                {#each event.dates as date}
                    {#if date.is_confirmed}
                        <div class="flex items-start gap-sm p-1 bg-white mb-1">
                            <div class="flex-1">
                                {dDMY(date.date_at)}, {date.hour}
                            </div>
                            <IconButton button="calendar" color="00aa00" on:click={() => unFix(date)}/>
                        </div>
                    {/if}
                {/each}
            {:else}
                <!-- event is NOT approved, show all dates and allow editing -->
                {#if event.status != EVENT_STATUS.EVENT_STATUS_COMPLETED}
                    {#each event.dates as date}
                        {@const past = isPast(parseISO(date.date_at))}
                        <div class="flex items-start gap-sm p-1 bg-white mb-1" class:bg-amber-100={past}>

                            <IconButton title="Διαγραφή ημερομηνίας" color="ff0000" button="minus-circle"
                                        on:click={() => deleteDate(date)}/>
                            <div class="flex-1 flex items-center gap-1">
                                {dDMY(date.date_at)}, {date.hour}
                                {#if past}
                                    <Icon class="inline" size={22} icon="alert-circle" color="aa0000"
                                          title="Έχει περάσει η ημερομηνία. Το event δεν θα πρέπει να επιβεβαιωθεί σε αυτή την ημερομηνία"/>
                                {/if}
                            </div>

                            <IconButton title="Οριστικοποίηση ημερομηνίας" color="00aa00" button="check-circle"
                                        on:click={() => fixDate(date, past)}/>

                        </div>
                    {/each}
                {:else}
                    {#each event.dates as date}
                        {#if date.is_confirmed}
                            <div class="flex items-start gap-sm p-1 bg-white mb-1">
                                <div class="flex-1">
                                    {dDMY(date.date_at)}, {date.hour}
                                </div>
                            </div>
                        {/if}
                    {/each}
                {/if}
            {/if}
        </div>


        <Spacer size="xs"/>
        <div class="text-gray border-b border-b-zinc-100 bg-white text-sm flex items-center gap-sm p-1">
            <button on:click={() => moneyExpanded = !moneyExpanded}>
                {#if moneyExpanded}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            <span class="flex-1">
                {event.payments.length == 1 ? 'Πληρωμή' : 'Πληρωμές'}
                ({event.payments.reduce((t, p) => t + (+p.amount), 0)}€)</span>
            {#if event.paid_at}
                        <span class="text-green">
                            Το event φαίνεται εξοφλημένο
                        </span>
            {/if}
            {#if !event.paid_at}
                <IconButton title="Προσθήκη πληρωμής" color="00aa00" button="plus-circle"
                            on:click={showAddPaymentDialog}/>
            {/if}
        </div>
        {#if moneyExpanded}
            <div class="p-sm bg-white text-xs">
                {#each event.payments as payment}
                    <QvPaymentItem {payment} {event} on:click={editPayment(payment)} readOnly={event.paid_at}/>
                {/each}
            </div>
        {/if}

        <Spacer size="xs"/>
        <div class="text-gray border-b border-b-zinc-100 bg-white text-sm flex items-center gap-sm p-1">
            <button on:click={() => detailsExpanded = !detailsExpanded}>
                {#if detailsExpanded}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            <span class="flex-1">Ενέργειες</span>
        </div>
        {#if detailsExpanded}
            <div class="p-sm bg-white text-xs">
                {#if event.status == EVENT_STATUS.EVENT_STATUS_COMPLETED}
                    <div class="p-sm bg-white text-md text-center text-red border border-b-red m-2">
                        Το event έχει ολοκληρωθεί
                    </div>
                {/if}
                <EventProsxedio {event} {venue}/>

                {#if event.status == EVENT_STATUS.EVENT_STATUS_APPROVED}
                    <EventServices {event} {room}/>
                    <EventContract {event} {venue}/>
                    {#if event.contract}
                        <EventTae {event} {venue}/>
                        <div class="p-1">
                            &bull;
                            <a href="/print/{event.id}/kitchen"
                               target="_blank"
                               class="font-bold underline">
                                Έντυπο κουζίνας
                            </a>
                        </div>
                    {:else}
                        <div class="error">
                            Συμπληρώστε το συμφωνητικό για να μπορέσετε να τυπώσετε το έντυπο ΤΑΕ και να εκτυπώσετε
                            το έντυπο κουζίνας.
                        </div>
                    {/if}
                {:else}
                    <div class="text-red">Θα πρέπει να οριστικοποιηθεί το event για να συμπληρώσετε
                        συμφωνητικό
                    </div>
                {/if}
            </div>

            <div class="text-xs text-right p-2 bg-white">
                {#if event.status == EVENT_STATUS.EVENT_STATUS_CANCELED}
                    <button class="btn btn-ok" on:click={unCancelEvent}>Ενεργοποίηση</button>
                {/if}

                {#if event.status != EVENT_STATUS.EVENT_STATUS_CANCELED}
                    <button class="btn btn-danger" on:click={cancelEvent}>Ακύρωση event</button>
                {/if}
                {#if event.status == EVENT_STATUS.EVENT_STATUS_APPROVED}
                    {#if isPast(parseISO(event.approved_at))}
                        <button class="btn btn-ok" on:click={showCompleteEventPopup}>Ολοκλήρωση</button>
                    {:else}
                        <button class="btn btn-gray text-gray-light"
                                title="Δεν έχει έρθει ακόμα η ημερομηνία διεξαγωγής">Ολοκλήρωση
                        </button>
                    {/if}
                {/if}
            </div>

        {/if}
        <Spacer size="xs"/>
        <div class="text-gray border-b border-b-zinc-100 bg-white text-sm flex items-center gap-sm p-1">
            <button on:click={() => notesExpanded = !notesExpanded}>
                {#if notesExpanded}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            <span class="flex-1">Σημειώσεις ({event.notes.length})</span>
            {#if notesExpanded}
                <IconButton title="Προσθήκη σημείωσης" color="00aa00" button="plus-circle" on:click={addNote}/>
            {/if}
        </div>
        {#if notesExpanded}
            {#each event.notes as note}
                <NoteItem
                        on:delete={redraw}
                        {note}
                        entity={event}
                />
            {/each}
        {/if}
        <hr>
        <div class="text-right">
            <button class="btn btn-link underline" on:click={closeDetailsPopup}>Κλείσιμο</button>
        </div>
    {/key}
{:else}
    <div>Loading...</div>
{/if}

{#if addDatePopupVisible}
    <ScreenCenter title="Επιλογή ημερομηνίας" on:close={hideAddDateDialog}>
        <EventDateSelector
                date={selectedDate}
                room={event.room_id}
                on:close={hideAddDateDialog}
                on:select={addDate}/>
    </ScreenCenter>
{/if}

{#if contractPopupVisible}
    <ScreenCenter title="Εκτύπωση συμφωνητικού" on:close={hideContractPopup}>
        <ContractData
                contract={event.contract}
                contacts={event.contacts}
                on:close={hideContractPopup}
                on:save={saveContactData}
                on:print={toggleContractPrint}
        />
    </ScreenCenter>
{/if}

{#if addPaymentPopupVisible}
    <ScreenCenter title="Προσθήκη πληρωμής" on:close={hideAddPaymentDialog}>
        <PaymentEditor
                {event}
                {showPaymentBeforeFixing}
                payment={selectedPayment}
                on:close={hideAddPaymentDialog}/>
    </ScreenCenter>
{/if}

{#if showCompletePopupVisible}
    <ScreenCenter title="Εκκαθάριση" on:close={hideCompleteEventPopup}>
        <EventDone
                {event}
                on:reload={init}
                on:close={hideCompleteEventPopup}/>
    </ScreenCenter>
{/if}


