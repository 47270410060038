<script>
    import {venues$} from "../../lib/lib.js";
    import ScreenCenter from "../ScreenCenter.svelte";
    import VenueEditor from "./VenueEditor.svelte";
    import RoomEditor from "./rooms/RoomEditor.svelte";
    import VenueItem from "./VenueItem.svelte";
    import Icon from "../layout/Icon.svelte";

    export let parts;

    let addVenueDialogVisible = false;
    let addRoomDialogVisible = false;

    let venue = false;
    let room = false;
    let selected = null;


    const showAddVenueDialog = ({detail: v = null}) => {
        venue = v;
        addVenueDialogVisible = true;
    };

    const hideAddVenueDialog = () => {
        venue = null;
        addVenueDialogVisible = false;
    };

    const showAddRoomDialog = ({detail: v = null}, r = null) => {
        venue = v;
        room = r || {};
        addRoomDialogVisible = true;
    };
    const showEditRoomDialog = ({detail: pair}) => {
        dd(pair);
        venue = pair.venue;
        room = pair.room;
        addRoomDialogVisible = true;
    };


    const hideAddRoomDialog = () => {
        venue = null;

        room = null;
        addRoomDialogVisible = false;
    };


    $: {
        selected = parts[3] ?? 0;
    }

</script>


<div class="main-place pb-xl">
    {#each $venues$ as venue}
        <VenueItem {selected} {venue}
                   on:addRoom={showAddRoomDialog}
                   on:editRoom={showEditRoomDialog}
                   on:deleteRoom={showAddRoomDialog}
                   on:editVenue={showAddVenueDialog}
        />
    {/each}

    <div class="flex justify-end px-sm">
        <button class="btn px-sm flex gap-2 text-sm" on:click={showAddVenueDialog}>
            <Icon color="00aa00" icon="plus-circle"/>
            <span>Εγκατάσταση</span>
        </button>
    </div>
</div>

{#if addVenueDialogVisible}
    <ScreenCenter on:close={hideAddVenueDialog} title="{venue?.id ? 'Επεξεργασία':'Προσθήκη'} εγκατάστασης">
        <VenueEditor
                on:close={hideAddVenueDialog}
                on:save={hideAddVenueDialog}
                {venue}/>
    </ScreenCenter>
{/if}

{#if addRoomDialogVisible}
    <ScreenCenter on:close={hideAddRoomDialog} title="{room?.id ? 'Επεξεργασία':'Προσθήκη'} αίθουσας">
        <RoomEditor
                on:close={hideAddRoomDialog}
                on:save={hideAddRoomDialog}
                {venue} {room}/>
    </ScreenCenter>
{/if}