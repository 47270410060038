<script>
    import A from "../../lib/Router/A.svelte";

    export let bg = '#ffffff00';
    export let color = 'currentColor';
    export let button = 'pencil';
    export let href = null;
    export let title = '';
    export let size = 22;
    if (button == 'edit') button = 'pencil';
    if (button == 'save') button = 'content-save';
    if (button == 'back') button = 'chevron-left';
</script>

{#if href}
    <A {title} class="btn-round leading-[5px] flex gap-1 items-center text-sm no-focus-ring p-1 pr-3 cursor-pointer" style="background-color: {bg}" {href}>
        <img src="https://icongr.am/material/{button}.svg?size={size}&color={color}" alt=""><slot/>
    </A>
{:else}
    <button {title} class="flex gap-1 items-center text-sm no-focus-ring cursor-pointer" on:click style="background-color:{bg}">
        <img src="https://icongr.am/material/{button}.svg?size={size}&color={color}" alt="-"><slot/>
    </button>
{/if}