<script>

    import {API, copyObj, billables$} from "../../lib/lib.js";
    import ScreenCenter from "../ScreenCenter.svelte";
    import PairEditor from "../varius/PairEditor.svelte";
    import ErrorDisplay from "../layout/ErrorDisplay.svelte";
    import Icon from "../layout/Icon.svelte";
    import BillableItem from "./BillableItem.svelte";

    let selectedBillable = null;
    let editorVisible = false;
    let errors = null;

    const closeEditor = () => editorVisible = false;

    const showEditor = ({detail: data}) => {
        data = data || {name:''};
        selectedBillable = copyObj(data);
        editorVisible = true;
    };

    const saveExtra = async () => {
        errors = '';
        selectedBillable.name = selectedBillable.name.trim();
        if (selectedBillable.name.length < 2) {
            errors = ['Πρέπει να δώσετε ένα σωστό τίτλο'];
            return;
        }

        const doEdit = async data => {
            const res = await API.patch(`billable/${data.id}`, data);

            if (hasError(res.error)) {
                return;
            }

            billables$.update(store => {
                return store.map(ed => {
                    if (ed.id == data.id) {
                        ed.name = data.name;
                    }
                    return ed;
                });
            });

            showMessage('Αποθηκεύτηκε!');
            closeEditor();
        };

        const doAdd = async extraData => {
            const res = await API.post(`billable`, extraData);
            if (hasError(res.error)) {
                return;
            }

            $billables$ = [...$billables$, res.data];
            showMessage('Αποθηκεύτηκε!');
            selectedBillable = null;
            editorVisible = false;
        };

        const hasError = arg => {
            if (arg == true) {
                errors = res.errors;
                showMessage('Κάποιο λάθος συνέβη');
                return true;
            }

            return false;
        };

        if (selectedBillable.id) {
            doEdit(selectedBillable);
        } else {
            doAdd(selectedBillable);
        }
    };
</script>


<div class="main-place">
    {#each $billables$ as data}
        <BillableItem on:edit={showEditor} {data}/>
    {/each}

    <div class="p-sm bg-white flex items-center gap-sm justify-end">
        <button class="btn px-sm flex gap-2 text-sm" on:click={() => showEditor({})}>
            <Icon color="00aa00" icon="plus-circle"/>
            <span>Δημιουργία</span>
        </button>
    </div>
</div>


{#if editorVisible}
    <ScreenCenter title="{selectedBillable?.id ?  'Επεξεργασία' : 'Προσθήκη'}" on:close={closeEditor}>

        <PairEditor full title="Όνομα κατηγορίας">
            <input type="text" bind:value={selectedBillable.name}>
        </PairEditor>

        <ErrorDisplay bind:errors/>
        <hr>
        <div class="text-right">
            <button class="btn btn-link underline" on:click={closeEditor}>Κλείσιμο</button>
            <button class="btn btn-ok" on:click={saveExtra}>Αποθήκευση</button>
        </div>
    </ScreenCenter>
{/if}



