<script>
    import StickyTop from "../layout/StickyTop.svelte";
    import SettingsMenu from "./SettingsMenu.svelte";
    import Route from "../../lib/Router/Router.svelte";
    import VenuesList from "../venues/VenuesList.svelte";
    import TypeList from "../types/TypeList.svelte";
    import ServiceCategoriesList from "../services/ServiceCategoriesList.svelte";
    import RoomServices from "../venues/rooms/RoomServices.svelte";
    import SettingsExtra from "../extra/SettingsExtra.svelte";
    import BillableList from "../billable/BillableList.svelte";
    import TermList from "../terms/TermList.svelte";
</script>

<div style="display: grid; grid-auto-flow: row; grid-template-rows: 46px 1fr; overflow-y: hidden">
    <StickyTop>
        <SettingsMenu/>
    </StickyTop>

    <div>
        <Route exact redir="/app/settings/venues"/>

        <Route match="venues" let:router>
            <VenuesList parts={router.parts}/>
        </Route>
        <Route match="rooms" let:router>
            <Route exact redir="/app/settings/venues"/>
            <Route match="*" let:router>
                <RoomServices id={router.star}/>
            </Route>
        </Route>

        <Route match="eventTypes">
            <TypeList/>
        </Route>

        <Route match="extra">
            <SettingsExtra/>
        </Route>

        <Route match="services">
            <ServiceCategoriesList/>
        </Route>

        <Route match="billables">
            <BillableList/>
        </Route>

        <Route match="terms">
            <TermList/>
        </Route>
    </div>
</div>