<script>
    import Spacer from '../layout/Spacer.svelte';
    import {createEventDispatcher} from 'svelte';
    import IconButton from '../layout/IconButton.svelte';
    import PairEditor from '../varius/PairEditor.svelte';

    export let contacts;
    export let contract;

    export let venue;


    contract = contract || {
        admin_name: venue.incharge,
        admin_phones: venue.phones,
    };

    contract.extralines = [...(contract.extralines || []), '', ''];

    const dispatch = createEventDispatcher();

    let selectedNames = new Set((contract && contract.contacts) ? contract.contacts : []);

    let selectedPhones = new Set((contract && contract.phones) ? contract.phones : []);

    const toggleContact = item => {
        if (selectedNames.has(item)) {
            selectedNames.delete(item);
        } else {
            selectedNames.add(item);
        }
        contacts = contacts;
    };

    const togglePhone = item => {
        if (selectedPhones.has(item)) {
            selectedPhones.delete(item);
        } else {
            selectedPhones.add(item);
        }
        contacts = contacts;
    };

    let active;

    // setInterval(() => dd(contract), 2000);
    $: if (contacts) {
        active = ((selectedNames.size > 0) && (selectedPhones.size > 0));
    }
</script>

<div class="text-sm">

    <PairEditor title="Υπεύθυνος" span="2">
        <input type="text" bind:value={contract.admin_name}>
    </PairEditor>
    <PairEditor title="Τηλέφωνα" span="2">
        <input type="text" bind:value={contract.admin_phones}>
    </PairEditor>

    <Spacer/>
    <h4 class="text-sm py-1 font-thin">Επιλέξτε επαφές που θα εμφανίζονται στο προσχέδιο</h4>

    {#each contacts as contact}
        {@const name=`${contact.first_name} ${contact.last_name}`}
        {@const hasName = selectedNames.has(name)}
        <div class="ml-2 mb-1 bg-white py-1 px-sm flex items-center gap-sm rounded border-zinc-200 border"
             on:click={() => toggleContact(name)}>
            <IconButton
                    button={hasName ? 'check-circle' : 'circle-outline'}
                    color={hasName ? '00aa00':'cccccc'}/>
            <span class="flex-1">{name}</span>
            <span class="text-gray text-xs font-thin">
                {contact.pivot.title ? `(${contact.pivot.title})` : ''}
            </span>
        </div>
    {/each}

    <Spacer/>
    <h4 class="text-sm py-1 font-thin">Επιλέξτε τηλέφωνα που θα εμφανίζονται στο προσχέδιο</h4>
    {#each contacts as contact}
        {@const hasMobile = selectedPhones.has(contact.mobile)}
        {@const hasPhone = selectedPhones.has(contact.phone)}
        <div class="ml-2 mb-1 bg-white py-1 px-sm flex items-center gap-sm rounded border-zinc-200 border"
             on:click={() => togglePhone(contact.mobile)}>
            <IconButton
                    button={hasMobile ? 'check-circle' : 'circle-outline'}
                    color={hasMobile ? '00aa00':'cccccc'}/>
            <span class="flex-1">{contact.mobile}</span>
            <span class="text-gray text-xs font-thin">
                ({contact.first_name} {contact.last_name})
            </span>
        </div>
        {#if contact.phone}
            <div class="ml-2 mb-1 bg-white py-1 px-sm flex items-center gap-sm rounded border-zinc-200 border"
                 on:click={() => togglePhone(contact.phone)}>
                <IconButton
                        button={hasPhone ? 'check-circle' : 'circle-outline'}
                        color={hasPhone ? '00aa00':'cccccc'}/>
                <span class="flex-1">{contact.phone}</span>
                <span class="text-gray text-xs font-thin">
                ({contact.first_name} {contact.last_name})
            </span>
            </div>
        {/if}
    {/each}


    <Spacer/>
    <h4 class="text-sm py-1 font-thin">Έξτρα όροι</h4>
    <div class="p-1">
        {#each contract.extralines as line, idx}
            <label class="py-1 block">
                <input type="text" bind:value={contract.extralines[idx]} placeholder="Γραμμή #{idx+1}"/>
            </label>
        {/each}
    </div>


    <hr>
    <div class="text-right">
        <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
        {#if active}
            <button class="btn btn-ok" on:click={() => dispatch('save', {
            contacts: [...selectedNames],
            phones: [...selectedPhones],
            admin_name: contract.admin_name,
            admin_phones: contract.admin_phones
        })}>Αποθήκευση
            </button>
        {:else }
            <button class="btn btn-gray" disabled>
                Αποθήκευση
            </button>
        {/if}
    </div>
</div>