<script>
    import {contacts$, makeSearchable} from "../../lib/lib.js";
    import {showContactEditPanel} from './ContactsPanel.svelte';
    import Icon from "../layout/Icon.svelte";
    import A from "../../lib/Router/A.svelte";

    const addContact = () => showContactEditPanel(0);
    let filtered = [];
    let searchText = '';

    const filter = (s, c) => {
        s = makeSearchable(s);
        return $contacts$.filter(x => x.__search.includes(s));
    };

    $: filtered = filter(searchText, $contacts$);

</script>

<div class="flex border-b border-zinc-400 text-lg px-md py-xs bg-zinc-50 font-bold items-center">
    <div class="flex-1">Επαφές</div>
    <button class="btn px-sm flex gap-2 text-sm" on:click={addContact}>
        <Icon color="00aa00" icon="plus-circle"/>
        <span>Επαφή</span>
    </button>
</div>
<div class="main-place">
    <div class="mb-2">
        <input type="text" bind:value={searchText} placeholder="Αναζήτηση σε όνομα, επίθετο, τηλέφωνα...">
    </div>

    {#each filtered as contact}
        <div class="sre-item bg-bg">
            <div class="border-b border-b-gray-light p-sm flex items-center gap-sm">
                <div class="flex-1 flex gap-sm">
                    <A href={`/app/contact/${contact.id}`}>{contact.fullname}</A>
                </div>
                <button on:click={() => showContactEditPanel(contact.id)}>
                    <Icon icon="pencil-circle" color="000000"/>
                </button>
            </div>
        </div>
    {/each}
</div>