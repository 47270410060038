<script>
    import {initApp} from "./lib/lib.js";
    import Login from "./components/account/Login.svelte";
    import Route from "./lib/Router/Router.svelte";
    import Main from "./Main.svelte";
    import Sidebar from "./components/layout/Sidebar.svelte";


    let loaded = false;
    let logged = false;

    const init = async () => {
        logged = await initApp();
        loaded = true;
        if (!logged) {

        }
    };

    init();

</script>


<Route match="/">
    {#if !loaded}
        loading...
    {:else}
        {#if logged}
            <Route exact redir="/app"></Route>

            <Route match="app" breadcrumb="Home">
                <div class="h-full flex text-sm">
                    <Sidebar/>
                    <Main/>
                </div>
            </Route>
        {:else}
            <Login/>
        {/if}
    {/if}
</Route>