<script>
    import ErrorDisplay from "../../layout/ErrorDisplay.svelte";
    import {API, serviceCategories$} from "../../../lib/lib.js";
    import {focusFirstElement, trapKey} from "../../../lib/actions.js";
    import {createEventDispatcher} from "svelte";
    import Spacer from "../../layout/Spacer.svelte";
    import PublishedState from "../../varius/PublishedState.svelte";

    export let service;
    export let serviceCategory;

    if (service == null) {
        service = {is_active: 0};
    }

    if (serviceCategory == null) {
        serviceCategory = {};
    }

    const dispatch = createEventDispatcher();

    let errors = null;

    const serviceCopy = {
        name: service.name || '',
        service_category_id: service.service_category_id || serviceCategory.id,
        is_active: service.is_active || 0,
    };

    const resetServiceForm = () => {
        serviceCopy.name = '';
        serviceCopy.is_active = 0;
    };

    const saveService = () => {
        errors = null;

        if (service.id) {
            updateService();
        } else {
            createNewService();
        }
    };

    const createNewService = async () => {
        const res = await API.post(`serviceCategory/${serviceCategory.id}/createService`, serviceCopy);
        if (res.error == true) {
            errors = res.errors;
            return;
        }

        serviceCategories$.update(store => {
            store.map(category => {
                if (category.id == serviceCategory.id) {
                    category.services = [...category.services, res.data];
                }
            });
            return store;
        });

        resetServiceForm();

        showMessage('Η υπηρεσία/το προϊόν δημιουργήθηκε');
        dispatch('save', res.data);
    };

    const updateService = async () => {
        const res = await API.patch(`service/${service.id}`, serviceCopy);
        if (res.error == true) {
            errors = res.data;
            return;
        }

        serviceCategories$.update(store => {
            store.map(category => {
                if (category.id == serviceCategory.id) {
                    category.services.map(theService => {
                        if (service.id == theService.id) {
                            Object.assign(theService, serviceCopy);
                        }
                    });
                }
            });
            return store;
        });

        showMessage('Η υπηρεσία/το προϊόν αποθηκεύτηκε');
        dispatch('save', res.data);
    }

</script>


<div use:focusFirstElement>
    <div class="text-sm help">Επιλεγμένη κατηγορία: {serviceCategory.name}</div>
    <Spacer/>

    <h4>Όνομα</h4>

    <input type="text"
           bind:value={serviceCopy.name}
           use:trapKey={{callback: saveService}}
    >

    <Spacer/>
    <PublishedState bind:value={serviceCopy.is_active}/>
</div>

<ErrorDisplay bind:errors/>

<hr>
<div class="text-right">
    <button class="btn btn-normal" on:click={() => dispatch('close')}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveService}>Αποθήκευση</button>
</div>