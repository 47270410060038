<script context="module">
    import {get, writable} from "svelte/store";
    import {copyObj} from "../../lib/lib.js";

    let myStore$ = writable({
        visible: false,
        search: '',
        mode: 'search',
        contactClone: {}
    });

    let onHideCallback = async () => true;
    let onSelectContact = async contact => true;
    let onSaveCallback = async contact => true;


    export const showContactsPanel = (selectCallback, hideCallback = null) => {
        onSelectContact = selectCallback;
        if (hideCallback) {
            onHideCallback = hideCallback;
        }
        myStore$.update(s => {
            s.visible = true;
            return s;
        });
    };

    export const showContactEditPanel = (contact_id, saveCallback = null) => {
        const c = contact_id ? copyObj(get(contacts$).find(i => i.id == contact_id)) : {};
        delete c.__search;
        delete c.created_at;
        delete c.notes;
        delete c.fullname;

        myStore$.update(s => {
            s.visible = true;
            s.mode = 'edit';
            s.contactClone = c;
            return s;
        });
        if (saveCallback) {
            onSaveCallback = saveCallback;
        }


        setTimeout(() => {
            getAutoCompleteControl().value = c.first_name || '';
        }, 50);
    };

    const getAutoCompleteControl = () => document.getElementById('theName').querySelector('input[type="text"]');

    let hide = async () => {
        if ((await onHideCallback()) === false) {
            return;
        }
        myStore$.update(s => {
            s.visible = false;
            s.search = '';
            s.mode = 'search';
            s.contactClone = {};

            return s;
        });
    };

</script>

<script>
    import {API, contacts$, createContactSearchData, firstNames, makeSearchable} from "../../lib/lib.js";
    import ContactItem from "./ContactItem.svelte";
    import ScreenCenter from "../ScreenCenter.svelte";
    import Spacer from "../layout/Spacer.svelte";
    import AutoComplete from 'simple-svelte-autocomplete';

    let filtered = [];
    let isFromSearch = false;

    const createSearchField = collection => {
        return collection.map(contact => {
            contact.__search = `${contact.last_name.toLowerCase()}|${contact.first_name.toLowerCase()}|${contact.email.toLowerCase()}|${contact.mobile}|${contact.phone}`;

            return contact;
        });
    };

    const editContact = ({detail: contact}) => {
        isFromSearch = true;
        $myStore$.contactClone = JSON.parse(JSON.stringify(contact));
        $myStore$.mode = 'edit';
        setTimeout(() => {
            getAutoCompleteControl().value = contact.first_name || '';
        }, 50)
    };

    const selectContact = async ({detail: contact}) => {
        if (await onSelectContact(contact) === false) {
            return;
        }
        await hide();
    };


    const filter = (s, c) => {
        s = makeSearchable(s);
        return $contacts$.filter(x => x.__search.includes(s));
    };


    const save = async () => {
        $myStore$.contactClone.first_name = getAutoCompleteControl().value;

        let result;
        if ($myStore$.contactClone.id) {
            result = await API.patch(`contact/${$myStore$.contactClone.id}`, $myStore$.contactClone);
        } else {
            result = await API.post(`contact`, $myStore$.contactClone);
        }

        if (result.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        if ($myStore$.contactClone.id) {
            $contacts$ = $contacts$.map(c => {
                if (c.id == $myStore$.contactClone.id) {
                    return createContactSearchData(result.data)
                } else {
                    return c;
                }
            });
        } else {
            $contacts$ = [...$contacts$, createContactSearchData(result.data)];
        }

        showMessage('Επιτυχής αποθήκευση πελάτη');

        if (isFromSearch) {
            $myStore$.mode = 'search';
            $myStore$.contactClone = {};
        } else {
            if ((await onSaveCallback(result.data)) === false) {
                return;
            }
            hide();
        }
    };

    const closeEdit = () => {
        if (isFromSearch) {
            $myStore$.mode = 'search';
            $myStore$.contactClone = {};
        } else {
            hide();
        }
    };

    const addContact = () => {
        $myStore$.contactClone = {};
        $myStore$.mode = 'edit'
    };

    const names = [...new Set([...firstNames, ...$contacts$.map(i => i.first_name)])];

    $: filtered = filter($myStore$.search, $contacts$);
</script>

{#if $myStore$.visible}
    <ScreenCenter title="επαφές" on:close={hide} closesOnEscape showClose>
        <div class="">
            {#if $myStore$.mode == 'search'}
                <div class="flex justify-between">
                    <input type="text" placeholder="search" bind:value={$myStore$.search}>
                    <button class="btn btn-normal" on:click={addContact}>+</button>
                </div>

                <div class="flex-1 bg-bg">
                    <div class="h-full list mt-md overflow-y-auto max-h-72 bg-white border border-zinc-300 shadow-inner">
                        {#each filtered as contact}
                            <ContactItem {contact} on:select={selectContact} on:edit={editContact}/>
                        {:else }
                            <div class="text-center p-lg no-list ">Δεν βρέθηκαν επαφές</div>
                        {/each}
                    </div>
                </div>
            {:else}
                <h2>
                    {$myStore$.contactClone.id ? 'Επεξεργασία' : 'Προσθήκη'} επαφής
                </h2>
                <div class="flex-1 text-sm">
                    <div class="grid-cols-2 grid items-center gap-sm">
                        <div id="theName">
                            <div>Όνομα</div>
                            <div>
                                <AutoComplete items={names}/>
                            </div>
                        </div>

                        <div>
                            <div>Επίθετο</div>
                            <div>
                                <input type="text" bind:value={$myStore$.contactClone.last_name}>
                            </div>
                        </div>
                    </div>

                    <div class="grid-cols-2 grid items-center gap-sm">
                        <div>
                            <Spacer/>
                            <div>Κινητό</div>
                            <div>
                                <input type="tel" bind:value={$myStore$.contactClone.mobile}>
                            </div>
                        </div>

                        <div>
                            <Spacer/>
                            <div>Κινητό #2</div>
                            <div>
                                <input type="tel" bind:value={$myStore$.contactClone.phone}>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Spacer/>
                        <div>Email</div>
                        <div>
                            <input type="email" bind:value={$myStore$.contactClone.email}>
                        </div>
                    </div>
                    <hr>
                    <div class="flex mt-2">
                        <button class="flex-1 btn btn-link underline" on:click={closeEdit}>Κλείσιμο</button>
                        <button class="flex-1 btn btn-ok" on:click={save}>Save</button>
                    </div>
                </div>
            {/if}
        </div>
    </ScreenCenter>
{/if}
