<script>
    import Route from '../../lib/Router/Router.svelte';
    import MonthDaysList from './MonthDaysList.svelte';
    import IconButton from '../layout/IconButton.svelte';
    import A from '../../lib/Router/A.svelte';
    import {dateToYYMM} from '../../lib/lib.js';

    const getRedir = () => {
        const d = dateToYYMM().split('-').map(Number);
        return d.join('-');
    }
</script>


<div class="main-place relative">
    <Route exact redir={`/app/events/${getRedir()}`}>
    </Route>
    <Route match="*" let:router>
        <MonthDaysList
                year={router.star.split('-')[0]}
                month={router.star.split('-')[1]}
        />
        <div class="fixed bottom-sm right-sm bg-red rounded-full">
            <A href="/app/new-event">
                <IconButton button="plus" color="ffffff" bg="ffffffff" size={42}/>
            </A>
        </div>
    </Route>
</div>
