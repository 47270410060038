<script>
    import {createEventDispatcher} from "svelte";
    import SquareButton from "../layout/SquareButton.svelte";
    import IconButton from "../layout/IconButton.svelte";

    export let contact;
    const dispatch = createEventDispatcher();
    const remove = () => dispatch('remove', contact);
</script>


<div class="bg-white py-1 mb-1 border-zinc-200 border-b">
    <div class="flex gap-sm items-center">
        <div class="w-6">
            <IconButton button="minus-circle" color="FF0000" on:click={remove}/>
        </div>
        <div class="whitespace-nowrap text-sm flex-1">
            {contact.first_name} {contact.last_name}
        </div>
        <input type="text" bind:value={contact.title} class="w-32 text-sm btn-underline"
               placeholder="γαμπρός, νυφη..."/>
    </div>
</div>