<script>
    import {API, DMY, EVENT_STATUS, eventTypes$, findItem, venues$} from '../../lib/lib.js';
    import Icon from '../layout/Icon.svelte';
    import {showContactEditPanel} from './ContactsPanel.svelte';
    import A from '../../lib/Router/A.svelte';
    import NoteItem from '../Ev/qv/NoteItem.svelte';
    import Spacer from '../layout/Spacer.svelte';
    import ScreenCenter from '../ScreenCenter.svelte';
    import EventQuickView from '../Ev/EventQuickView.svelte';


    export let id;

    let loading = false;
    let contact = {};
    let counter = 0;

    let selectedEventId = 0;

    const editContact = id => () => {
        showContactEditPanel(id, c => {
            counter++;
        });
    };

    const edit = () => {
        showContactEditPanel(id, c => {
            contact.first_name = c.first_name;
            contact.last_name = c.last_name;
            contact.mobile = c.mobile;
            contact.phone = c.phone;
            contact.email = c.email;
        });
    };
    const init = async () => {
        counter++;
        loading = true;
        const res = await API.get(`contact/${id}/details`);
        contact = res.data;
        loading = false;
    };

    const addNote = () => {
        contact.notes = [...contact.notes, {id: 0, body: ''}];
    };

    const setEditEvent = id => () => {
        selectedEventId = id;
    };

    const determineEventAction = ({detail: spec}) => {
        if (spec.action == 'fix') {
            contact.events.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_APPROVED;
                    event.approved_at = spec.date;
                    event.completed_at = null;
                    event.canceled_at = null;
                }
            });
        }

        if (spec.action == 'delete') {
            contact.events.filter(event => event.id != spec.event_id);
        }

        if (spec.action == 'unfix') {
            contact.events.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_NEW;
                    event.approved_at = null;
                    event.completed_at = null;
                    event.canceled_at = null;
                }
            });
        }

        if (spec.action == 'cancel') {
            contact.events.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_CANCELED;
                    event.approved_at = null;
                    event.completed_at = null;
                    event.canceled_at = spec.cancel_time;
                }
            });
        }

        if (spec.action == 'uncancel') {
            contact.events.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_NEW;
                    event.approved_at = null;
                    event.completed_at = null;
                    event.canceled_at = null;
                }
            });
        }

        contact.events = contact.events;
        dd(contact.events)
    };


    $: init(id, counter);
</script>


{#key counter}
    {#if loading}
        loading...
    {:else}
        <div class="border-b border-zinc-400 text-lg px-md py-xs bg-zinc-50 font-bold flex gap-sm">
            Λεπτομέρειες επαφής
            <Icon color="00aa00" icon="pencil-circle" on:click={edit} title="Επεξεργασία"/>
        </div>
        <div class="main-place pt-1">
            <div class="grid grid-cols-3 items-center gap-x-1 pl-sm text-sm">
                <div class="p-1 pr-2 mb-1 text-right bg-zinc-100">ονομα</div>
                <div class="p-1 pr-2 mb-1 col-span-2">{contact.first_name}</div>
                <div class="p-1 pr-2 mb-1 text-right bg-zinc-100">Επώνυμο</div>
                <div class="p-1 pr-2 mb-1 col-span-2">{contact.last_name}</div>
                <div class="p-1 pr-2 mb-1 text-right bg-zinc-100">Κινητό</div>
                <div class="p-1 pr-2 mb-1 col-span-2">{contact.mobile}</div>
                <div class="p-1 pr-2 mb-1 text-right bg-zinc-100">Τηλέφωνο</div>
                <div class="p-1 pr-2 mb-1 col-span-2">{contact.phone}</div>
                <div class="p-1 pr-2 mb-1 text-right bg-zinc-100">email</div>
                <div class="p-1 pr-2 mb-1 col-span-2">{contact.email}</div>
            </div>

            <Spacer/>

            <h2 class="border-y p-1 px-sm bg-zinc-50 border-zinc-200 flex gap-sm">
                <span>Σημειώσεις</span>
                <Icon color="00aa00" icon="plus-circle" on:click={addNote} title="Προσθήκη Σημείωσης"/>
            </h2>
            <div class="pl-sm">
                {#each contact.notes as note}
                    <NoteItem
                            note={note}
                            entity={contact}
                            endpoint="contact"/>
                {/each}
            </div>

            <Spacer/>

            <h2 class="border-y p-1 px-sm bg-zinc-50 border-zinc-200 flex gap-sm items-center">
                <span>Events</span>
            </h2>
            <div class="pl-sm">
                {#each contact.events as event}
                    {@const venue = findItem(event.venue_id, $venues$)}
                    {@const room = findItem(event.room_id, venue.rooms)}
                    <div class="mb-sm border shadow-md border-zinc-100"
                         style="border-left-width:5px; border-left-color:{venue.gutter_color}">
                        <div style="border-left-width:5px; border-left-color:{room.gutter_color}" class="pb-sm">
                            <div class="bold mb-sm ev-{event.status} p-1 px-sm flex gap-sm items-center">
                                <div class="flex-1">
                                    {findItem(event.event_type_id, $eventTypes$).name}
                                    ({venue.name} - {room.name})
                                </div>
                                <button on:click={setEditEvent(event.id)}>
                                    <Icon class="shadow bg-white" icon="chevron-right" color="00dd00"/>
                                </button>

                            </div>

                            <div class="pl-sm">
                                <div class="mb-sm">
                                    {#each event.dates as date}
                                    <span
                                            class:bg-zinc-50={event.approved_at == null}
                                            class:bg-yellow={event.approved_at != null && event.approved_at == date.date_at}
                                            class="text-xs inline-block p-1 mr-2 border-zinc-200 border rounded">
                                        {date.date_at}, {date.hour}
                                    </span>
                                    {/each}
                                </div>

                                <div class="mb-1">
                                    {#each event.contacts as c}
                            <span class="text-xs p-1 bg-zinc-50 mr-2 border-zinc-200 border rounded inline-flex items-center gap-sm">
                                <A href="/app/contact/{c.id}">
                                    {c.first_name} {c.last_name} ({c.pivot.title})
                                </A>
                                <Icon color="00aa00" icon="pencil-circle" on:click={editContact(c.id)}
                                      title="Επεξεργασία"/>
                            </span>
                                    {/each}
                                </div>
                            </div>
                        </div>
                    </div>
                {/each}

                <h2 class="border-y p-1 px-sm bg-zinc-50 border-zinc-200 flex gap-sm">
                    <span>Πληρωμές</span>
                </h2>
                <div class="pl-sm text-xs">
                    {#if contact.payments.length > 0}
                        <table width="100%" class="stripped" style="vertical-align: top">
                            <tr>
                                <td class="font-bold">Περιγραφή</td>
                                <td class="font-bold">Ημ/νία</td>
                                <td class="font-bold">Ποσό</td>
                                <td class="font-bold" width="36">&nbsp;</td>
                            </tr>
                            {#each contact.payments as payment}
                                <tr>
                                    <td>
                                        {payment.description.title || payment.description}
                                        {#if payment.notes}
                                            <div class="text-xs text-gray">
                                                {payment.notes}
                                            </div>
                                        {/if}
                                    </td>
                                    <td>{DMY(payment.created_at)}</td>
                                    <td>{payment.amount}€</td>
                                    <td class="text-right">
                                        <button on:click={setEditEvent(payment.event_id)}>
                                            <Icon icon="chevron-right-circle" color="00dd00"/>
                                        </button>
                                    </td>
                                </tr>

                            {/each}
                        </table>
                    {:else}
                        <div class="p-sm text-center">
                            Δεν βρέθηκαν πληρωμές
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    {/if}
{/key}


{#if selectedEventId}
    <ScreenCenter title="Λεπτομέρειες">
        <EventQuickView
                on:event:change={determineEventAction}
                on:close={() => selectedEventId = null}
                id={selectedEventId}/>
    </ScreenCenter>
{/if}
<style>
    table tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    td {
        padding: 5px;
    }
</style>