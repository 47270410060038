<script>
    import {focusFirstElement, trapKey} from "../../lib/actions.js";
    import Section from "../layout/Section.svelte";
    import {API, eventTypes$} from "../../lib/lib.js";
    import ErrorDisplay from "../layout/ErrorDisplay.svelte";
    import {createEventDispatcher} from "svelte";

    export let type;

    const dispatch = createEventDispatcher();
    let errors = null;

    const typeCopy = {
        name: type?.name || ''
    };

    const saveType = () => {
        if (type?.id) {
            editType();
        } else {
            createType();
        }
    };

    const createType = async () => {
        const res = await API.post(`eventType`, typeCopy);
        if (res.error == true) {
            errors = res.errors;
            return;
        }
        eventTypes$.update(store => {
            return [...store, res.data];
        });

        showMessage('Ο τύπος δημιουργήθηκε');
        dispatch('save', res.data);
    };

    const editType = async () => {
        const res = await API.patch(`eventType/${type.id}`, typeCopy);
        if (res.error == true) {
            errors = res.errors;
            return;
        }

        eventTypes$.update(store => {
            return store.map(et => {
                if (et.id == type.id) {
                    return Object.assign(et, typeCopy)
                }
                return et;
            });
        });

        showMessage('Ο τύπος αποθηκεύτηκε');
        dispatch('save', res.data);
    }
</script>


<div use:focusFirstElement>
    <input type="text" bind:value={typeCopy.name} use:trapKey={{callback: saveType}}>
</div>

<ErrorDisplay bind:errors/>
<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveType}>Αποθήκευση</button>
</div>

