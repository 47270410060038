<script>
    import Icon from "../layout/Icon.svelte";

    export let value;
    export let label = 'Δημοσιευμένο';

    const toggle = () => value = Math.abs(value - 1);

    let color, icon;

    $: color = value ? '00aa00' : 'cccccc';
    $: icon = value ? 'check-circle' : 'circle-outline';
</script>


<button class="flex items-center gap-1 py-1" on:click={toggle}>
    <Icon {icon} {color}/>
    <span>{label}</span>
</button>