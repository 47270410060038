<script>
    import PairEditor from '../../varius/PairEditor.svelte';
    import {API, copyObj, isNumeric} from '../../../lib/lib.js';
    import ErrorDisplay from '../../layout/ErrorDisplay.svelte';
    import {createEventDispatcher} from 'svelte';

    export let payment;
    export let event;

    export let showPaymentBeforeFixing = false;

    let errors = null;

    const dispatch = createEventDispatcher();

    const paymentClone = payment ? copyObj(payment) : {
        id: 0,
        event_id: event.id,
        notes: '',
        amount: 0,
        description: {
            title: 'Προκαταβολή'
        },
        contact: {
            id: '0',
        }
    };

    paymentClone.contact_id = paymentClone.contact.id;
    if (!paymentClone.description) {
        paymentClone.description = {
            title: 'Προκαταβολή'
        };
    }
    delete paymentClone.contact;

    const closeEditor = () => {
        dispatch('close', {
            fix: false
        });
    };
    const closeAndFix = () => {
        dispatch('close', {
            fix: showPaymentBeforeFixing
        });
    };

    const saveExtra = () => {
        errors = '';
        if (!isNumeric(paymentClone.amount) || paymentClone.amount < 1) {
            errors = ['Πρέπει να δώσετε σωστό ποσό'];
            return;
        }
        if (paymentClone.contact_id == 0) {
            errors = ['Πρέπει να επιλέξετε μια επαφή'];
            return;
        }

        const doEdit = async data => {
            const res = await API.patch(`payment/${data.id}`, data);

            if (hasError(res.error)) {
                return;
            }

            event.payments = event.payments.map(p => {
                if (p.id != data.id) {
                    return p;
                }

                return res.data;
            });

            showMessage('Αποθηκεύτηκε!');
            closeEditor();
        };

        const doAdd = async data => {
            const res = await API.post(`payment`, data);
            if (hasError(res.error)) {
                return;
            }

            event.payments.push(res.data);

            showMessage('Αποθηκεύτηκε!');
            dispatch('close', {
                fix: showPaymentBeforeFixing
            });
        };

        const hasError = arg => {
            if (arg == true) {
                errors = res.errors;
                showMessage('Κάποιο λάθος συνέβη');
                return true;
            }

            return false;
        };

        if (paymentClone.id) {
            doEdit(paymentClone);
        } else {
            doAdd(paymentClone);
        }
    };
</script>

<div>
    {#if showPaymentBeforeFixing}
        <div class="my-2">
            Αν θέλετε, μπορείτε να προσθέσετε μια προκαταβολή για την καταχώρηση του event πατώντας "Οριστικοποίηση".
        </div>
        <div class="my-2">
            Αν δεν θέλετε να καταχωρήσετε πληρωμη, απλά πατήστε στο "Χωρίς πληρωμή".<br>
        </div>
        <div class="my-2">
            Αν δεν θέλετε να οριστικοποιήσετε το event, μπορείτε να πατήσετε στο "Ακύρωση"
        </div>
    {/if}
    <PairEditor title="Από">
        <select bind:value={paymentClone.contact_id}>
            <option value="0">Επιλέξτε επαφή</option>
            {#each event.contacts as contact}
                <option value={contact.id}>
                    {`${contact.first_name} ${contact.last_name}`}
                </option>
            {/each}
        </select>
    </PairEditor>

    <PairEditor title="Ποσό πληρωμής">
        <input type="text" bind:value={paymentClone.amount}/>
    </PairEditor>
    <PairEditor title="Αιτιολογία">
        <input type="text" bind:value={paymentClone.description.title}/>
    </PairEditor>

    <PairEditor full title="Σημειώσεις">
        <textarea bind:value={paymentClone.notes}></textarea>
    </PairEditor>

    <ErrorDisplay bind:errors/>
    <hr>
    {#if showPaymentBeforeFixing}
        <div class="text-right flex flex-col gap-lg">
            <div class="flex gap-xs">
                <button class="btn btn-normal block w-full" on:click={saveExtra}>Οριστικοποίηση</button>
                <button class="btn btn-ok block w-full" on:click={closeAndFix}>Χωρίς πληρωμή</button>
            </div>
            <button class="btn btn-link underline" on:click={closeEditor}>Ακύρωση οριστικοποίησης</button>
        </div>
    {:else}
        <button class="btn btn-link underline" on:click={closeEditor}>Κλείσιμο</button>
    {/if}
</div>