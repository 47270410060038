<script>
    import {API, contacts$, copyObj, dDMY, makeSearchable, venues$} from "../../lib/lib.js";
    import {focusFirstElement} from "../../lib/actions.js";
    import ErrorDisplay from "../layout/ErrorDisplay.svelte";
    import {createEventDispatcher} from "svelte";
    import IconButton from "../layout/IconButton.svelte";
    import ScreenCenter from "../ScreenCenter.svelte";
    import {showContactsPanel} from '../contacts/ContactsPanel.svelte';
    import EventContactItem from "../events/EventContactItem.svelte";
    import EventDateSelector from "./EventDateSelector.svelte";
    import NoteEditor from "../NoteEditor.svelte";

    export let date = null;


    let errors = [];
    let selectedRoom = null;
    let selectedContacts = [];
    let selectedDates = [];
    let selectedDate = null;

    if (date) {
        selectedDates = [{
            date_at: date,
            hour: '17:00'
        }];
    }

    let addDatePopupVisible = false;

    const dispatch = createEventDispatcher();


    const toSend = {
        venue_id: null,
        room_id: null,
        event_type_id: null,
        dates: [],
    };

    const onSelectContact = contact => {
        if (selectedContacts.filter(c => c.id == contact.id).length > 0) {
            showMessage('Έχετε ήδη επιλέξει αυτή την επαφή', 'bg-red');
            return false;
        }
        selectedContacts = [...selectedContacts, copyObj(contact)];
    };

    const removeContact = ({detail: contact}) => {
        selectedContacts = selectedContacts.filter(c => c != contact);
    };
    const addDate = ({detail: spec}) => {
        dd(spec.date_at)
        if (selectedDate) {
            selectedDates = selectedDates.filter(d => d.date_at != selectedDate.date_at);
        }
        if (selectedDates.filter(d => d.date_at == spec.date_at).length > 0) {
            showMessage('Η ημερομηνία αυτή έχει επιλεγεί ήδη', 'bg-red');
            return;
        }
        selectedDates = [...selectedDates, spec];
        hideAddDateDialog();
    };

    const removeDate = dt => () => {
        selectedDates = selectedDates.filter(d => d != dt);
    };

    const showAddContactDialog = () => showContactsPanel(onSelectContact);
    const showAddDateDialog = () => addDatePopupVisible = true;
    const hideAddDateDialog = () => {
        selectedDate = null;
        addDatePopupVisible = false;
    };

    const editDate = dt => () => {
        selectedDate = dt;
        showAddDateDialog();
    };

    const save = async () => {
        errors = [];
        toSend.venue_id = selectedRoom?.venue_id;
        toSend.room_id = selectedRoom?.id;
        toSend.contacts = selectedContacts?.map(c => ({id: c.id, title: c.title})) || null;
        toSend.dates = selectedDates || null;

        if (!toSend.venue_id) {
            errors.push('Δεν έχετε επιλέξει τοποθεσία');
        }

        if (toSend.contacts.length == 0) {
            errors.push('Δεν έχετε επιλέξει επαφές');
        }

        if (toSend.dates.length == 0) {
            errors.push('Δεν έχετε επιλέξει ημερομηνία');
        }

        if (!toSend.event_type_id) {
            errors.push('Δεν έχετε επιλέξει είδος εκδήλωσης');
        }

        if (errors.length > 0) {
            errors = errors;
            return;
        }

        const result = await API.post('event', toSend);

        if (result.error) {
            showMessage('Κάτι δεν πήγε καλά!', 'bg-red');
            return;
        }

        showMessage('Η προσθήκη έγινε');

        // get the toSend.dates[0] item (form is 2022-03-24 and get the year and date and join them with '-')
        const newDate = toSend.dates[0].date_at.split('-').splice(0, 2).join('-');
        setTimeout(() => document.location.href = `/app/events/${newDate}?id=${result.data.id}` , 1200)

    };

    const confirmClose = () => {
        dd('ok')
        if (!confirm('Σίγουρα θέλετε να ακυρώσετε την εισαγωγή;')) {
            return;
        }

        history.go(-1);
    };

    let hasOne = null;
    if ($venues$.length == 1) {
        if ($venues$[0].rooms.length == 1) {
            hasOne = `${$venues$[0].name} / ${$venues$[0].rooms[0].name}`;
            selectedRoom = $venues$[0].rooms[0];
        }
    }
</script>


<div class="main-place px-lg bg-zinc-100">
    <div use:focusFirstElement class="h-screen">
        <h2>Προσθήκη event</h2>

        <div class="box-item">
            <h4>Τοποθεσία</h4>
            {#if hasOne != null}
                {#if $venues$[0].rooms.length == 1}
                    {hasOne}
                {/if}
            {:else}
                <select bind:value={selectedRoom}>
                    {#each $venues$ as aVenue}
                        {#each aVenue.rooms as room}
                            <option value={room}>
                                {aVenue.name} / {room.name}
                            </option>
                        {/each}
                    {/each}
                </select>
            {/if}
        </div>


        {#if selectedRoom}
            <div class="box-item flex items-center gap-sm">
                <div class="w-4/5 whitespace-nowrap">
                    <h4>Είδος εκδήλωσης</h4>
                    <select bind:value={toSend.event_type_id}>
                        {#each selectedRoom.event_types as et}
                            <option value={et.id}>{et.name}</option>
                        {/each}
                    </select>
                </div>
                <div>
                    <h4>Ατομα</h4>
                    <input type="number" bind:value={toSend.guests_nr}>
                </div>
            </div>

            <div class="box-item">
                <h4 class="mb-1 flex">
                    <div class="flex-1">Επαφές</div>
                    <IconButton color="00aa00" button="plus-circle" on:click={showAddContactDialog}/>
                </h4>
                {#each selectedContacts as contact}
                    <EventContactItem {contact} on:remove={removeContact}/>
                {:else}
                    <div class="text-gray">Δεν έχετε επιλέξει επαφές</div>
                {/each}
            </div>

            <div class="box-item">
                <h4 class="mb-1 flex">
                    <div class="flex-1">Ημερομηνίες</div>
                    <IconButton color="00aa00" button="plus-circle" on:click={showAddDateDialog}/>
                </h4>
                {#each selectedDates as aDate}
                    <div class="bg-white py-1 mb-1 border-zinc-200 border-b flex items-center gap-sm">
                        <IconButton button="minus-circle" color="FF0000" on:click={removeDate(aDate)}/>
                        <div class="flex-1 text-sm">
                            {dDMY(aDate.date_at)} {aDate.hour}
                        </div>
                        <IconButton button="edit" color="0000ff" on:click={editDate(aDate)}/>
                    </div>
                {:else}
                    <div class="text-gray">Δεν έχετε επιλέξει ημερομηνίες</div>
                {/each}
            </div>

            <div class="box-item">
                <NoteEditor bind:notes={toSend.notes}/>
            </div>
        {/if}

        <ErrorDisplay bind:errors/>

        <hr>

        <div class="text-right">
            <button class="btn btn-link underline" on:click={confirmClose}>Κλείσιμο</button>
            <button class="btn btn-ok" on:click={save}>Αποθήκευση</button>
        </div>
    </div>
</div>


{#if addDatePopupVisible}
    <ScreenCenter title="Επιλογή ημερομηνίας" on:close={hideAddDateDialog}>
        <EventDateSelector
                date={selectedDate}
                room={selectedRoom.id}
                on:close={hideAddDateDialog}
                on:select={addDate}/>
    </ScreenCenter>
{/if}


<style>
    .box-item {
        @apply bg-zinc-50 pt-2 p-sm -mx-sm border-zinc-200 border-t border-b mb-2 shadow-md;
    }
</style>