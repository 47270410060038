<script>
    import IconButton from "../layout/IconButton.svelte";
    import {createEventDispatcher} from "svelte";
    import {API, dDMY, eventTypes$, findItem} from "../../lib/lib.js";
    import ErrorDisplay from "../layout/ErrorDisplay.svelte";
    import Spacer from "../layout/Spacer.svelte";

    export let room;
    export let date;

    const dispatch = createEventDispatcher();
    let date_at = date;
    let hour = '18:00';

    if (date) {
        date_at = date.date_at;
        hour = date.hour;
    }

    let loaded = false;
    let theEvs = [];
    let errors = [];

    const checkDate = async () => {
        theEvs = [];
        errors = [];
        loaded = false;
        const result = await API.post(`room/${room}/events`, {
            date: date_at
        });
        if (result.error) {
            errors = [result.message];
            return;
        }
        loaded = true;
        theEvs = result.data;
    };

    const save = () => {
        const b = (date_at || '').split(/\D/);
        const d = new Date(b[0], --b[1], b[2]);

        if (d instanceof Date && !isNaN(d)) {
            dispatch('select', {date_at: date_at, hour: hour});
            return;
        }

        errors = ['Επιλέξτε σωστή ημερομηνία/ώρα'];
    }
</script>

<div class="text-sm">
    <div>Παρακαλώ επιλέξτε ημερομηνία</div>
    <input type="date" bind:value={date_at} on:change={checkDate}>
    <h4 class="mt-2">Ώρα</h4>
    <select bind:value={hour}>
        {#each Array(10) as _, cnt}
            <option value={(cnt+12) + ':00'}>{cnt + 12}:00</option>
            <option value={(cnt+12) + ':30'}>{cnt + 12}:30</option>
        {/each}
    </select>
    {#if theEvs.length > 0}
        <Spacer/>
        <p class="text-xs p-2 font-bold">
            {theEvs.length} εκδηλώσεις την συγκεκριμένη ημέρα
        </p>
        {#each theEvs as ev}
            <div class="ev-{ev.status} p-2 text-xs rounded mb-2 shadow">
                <div class="font-bold">{ findItem(ev.event_type_id, $eventTypes$).name}</div>
                {#each ev.contacts as contact}
                    <div>
                        &bull; {contact.first_name} {contact.last_name} ({contact.pivot.title}
                        ) {contact.mobile} {contact.phone}
                    </div>
                {/each}
            </div>
        {/each}
    {/if}


    <ErrorDisplay {errors}/>
    <hr>
    <div class="text-right">
        <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
        {#if date_at}
            <button class="btn btn-ok" on:click={save}>Επιλογή</button>
        {/if}
    </div>
</div>