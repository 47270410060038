<script>
    import TypeEditor from "./TypeEditor.svelte";
    import ScreenCenter from "../ScreenCenter.svelte";
    import {eventTypes$} from "../../lib/lib.js";
    import EventTypeItem from "./EventTypeItem.svelte";
    import Icon from "../layout/Icon.svelte";

    let typeEditorPopupVisible = false;
    let type = null;

    const showEditTypePopup = ({detail:t = null}) => {
        type = t;
        typeEditorPopupVisible = true;
    };

    const hideEditTypePopup = (t = null) => {
        type = null;
        typeEditorPopupVisible = false;
    };
</script>

<div class="main-place">
    {#each $eventTypes$ as eventType}
        <EventTypeItem {eventType} on:edit={showEditTypePopup}/>
    {/each}

    <div class="p-sm bg-white flex items-center gap-sm justify-end">
        <button class="btn px-sm flex gap-2 text-sm" on:click={() => showEditTypePopup({})}>
            <Icon color="00aa00" icon="plus-circle"/>
            <span>Τύπος</span>
        </button>
    </div>
</div>

{#if typeEditorPopupVisible}
    <ScreenCenter title="{type == null ? 'Νέος τύπος εκδήλωσης' : 'Επεξεργασία τύπου'}" on:close={hideEditTypePopup}>
        <TypeEditor {type} on:close={hideEditTypePopup} on:save={hideEditTypePopup}/>
    </ScreenCenter>
{/if}

