<script>
    import {createEventDispatcher} from "svelte";
    import PairEditor from "../varius/PairEditor.svelte";
    import Spacer from "../layout/Spacer.svelte";
    import {extraData$} from "../../lib/lib.js";
    import ExtraDataContractItem from "./ExtraDataContractItem.svelte";
    import {noop} from "svelte/internal";
    import Icon from "../layout/Icon.svelte";

    export let contacts;
    export let contract;
    contract = contract || {};
    contract.extra = contract.extra || [];

    let selected;

    let opened = {
        'company': false,
        'client': false,
        'extra': true,
    };

    const dispatch = createEventDispatcher();
    contract = contract || {};

    const checkExtra = ({detail: spec}) => {
        // if ()
        // contract.extra
        const {data, checked} = spec;
        contract.extra = contract.extra.filter(e => e.id != data.id);
        if (checked) {
            contract.extra = [...contract.extra, data];
        }
    };

    const toggle = what => () => {
        opened[what] = !opened[what];
    };

    $: selected = contract.extra.map(x => x.id);
</script>
<div class="max-h-[560px] overflow-y-auto text-sm">
    <div class="p-1 font-thin border border-zinc-200 mb-2">
        <div class=" cursor-pointer flex gap-sm items-center text-lg" on:click={toggle('company')}>
            <button class="plain">
                {#if opened.company}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            Εταιρία
        </div>
        <div class="pl-sm" class:hidden={!opened.company}>
            <PairEditor title="Εταιρία">
                <input type="text" bind:value={contract.company}>
            </PairEditor>
            <PairEditor title="Υπεύθυνος">
                <input type="text" bind:value={contract.company_person}>
            </PairEditor>
            <PairEditor title="Α.Φ.Μ.">
                <input type="text" bind:value={contract.company_afm}>
            </PairEditor>
            <PairEditor title="Δ.Ο.Υ.">
                <input type="text" bind:value={contract.company_doy}>
            </PairEditor>
            <PairEditor title="Διεύθυνση">
                <input type="text" bind:value={contract.company_address}>
            </PairEditor>
            <Spacer/>
        </div>
    </div>


    <div class="p-1 font-thin border border-zinc-200 mb-2">
        <div class=" cursor-pointer flex gap-sm items-center text-lg" on:click={toggle('client')}>
            <button class="plain">
                {#if opened.client}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            Πελάτης
        </div>
        <div class="pl-sm" class:hidden={!opened.client}>
            <PairEditor title="Όνομα">
                <input type="text" bind:value={contract.client}>
            </PairEditor>
            <PairEditor title="Α.Φ.Μ.">
                <input type="text" bind:value={contract.client_afm}>
            </PairEditor>
            <PairEditor title="Δ.Ο.Υ.">
                <input type="text" bind:value={contract.client_doy}>
            </PairEditor>
            <PairEditor title="Διεύθυνση">
                <input type="text" bind:value={contract.client_address}>
            </PairEditor>
            <Spacer/>
        </div>
    </div>


    <div class="p-1 font-thin border border-zinc-200 mb-2">
        <div class=" cursor-pointer flex gap-sm items-center text-lg" on:click={toggle('extra')}>
            <button class="plain">
                {#if opened.extra}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            Extra
        </div>

        <div class="pl-sm" class:hidden={!opened.extra}>
            {#each $extraData$ as data}
                <ExtraDataContractItem checked={selected.indexOf(data.id) > -1} {data} on:toggle={checkExtra}/>
            {/each}
        </div>
    </div>

</div>
<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
</div>


<!--
<div class="text-sm">
    <h4 class="text-sm py-1">Επιλέξτε επαφές που θα εμφανίζονται στο προσχέδιο</h4>

    {#each contacts as contact}
        {@const name=`${contact.first_name} ${contact.last_name}`}
        <div class="ml-2 mb-1 bg-white py-1 px-sm flex items-center gap-sm rounded border-zinc-200 border"
             on:click={() => toggleContact(name)}>
            <IconButton button="check-circle" color={selectedNames.has(name) ? '00aa00':'cccccc'}/>
            {name}
            <span class="text-gray text-sm">
                ({contact.pivot.title})
            </span>
        </div>
    {/each}

    <Spacer/>
    <h4 class="text-sm py-1">Επιλέξτε τηλέφωνα που θα εμφανίζονται στο προσχέδιο</h4>
    {#each contacts as contact}
        <div class="ml-2 mb-1 bg-white py-1 px-sm flex items-center gap-sm rounded border-zinc-200 border"
             on:click={() => togglePhone(contact.mobile)}>
            <IconButton button="check-circle" color={selectedPhones.has(contact.mobile) ? '00aa00':'cccccc'}/>
            {contact.mobile}
            <span class="text-gray text-sm">
                από {contact.first_name} {contact.last_name} ({contact.pivot.title})
            </span>
        </div>
        {#if contact.phone}
            <div class="ml-2 mb-1 bg-white py-1 px-sm flex items-center gap-sm rounded border-zinc-200 border"
                 on:click={() => togglePhone(contact.phone)}>
                <IconButton button="check-circle" color={selectedPhones.has(contact.phone) ? '00aa00':'cccccc'}/>
                {contact.phone}
                <span class="text-gray text-sm">
                    από {contact.first_name} {contact.last_name} ({contact.pivot.title})
                </span>
            </div>
        {/if}
    {/each}

    <Spacer />
    <h4 class="text-sm py-1">Κατάσταση εκτύπωσης προσχέδιου</h4>
     <div class="ml-2 mb-1 py-1 px-sm flex items-center gap-sm rounded "
             on:click={togglePrinted}>
            <IconButton button="check-circle" color={selectedNames.has(name) ? '00aa00':'cccccc'}/>
            Είναι εκτυπωμένο
        </div>


        {#if active}
            <button class="btn btn-ok" on:click={() => dispatch('save', {
            contacts:[...selectedNames],
            phones:[...selectedPhones],
        })}>Αποθήκευση
            </button>
            {:else }
            <button class="btn btn-gray" disabled>
                Αποθήκευση
            </button>
        {/if}
    </div>
</div>

-->