<script>
    import {DMY, EVENT_STATUS, eventTypes$, findItem, venues$} from '../../lib/lib.js';
    import A from '../../lib/Router/A.svelte';
    import {router} from '../../lib/Router/Router.svelte';
    import Icon from '../layout/Icon.svelte';
    import {ensureInView} from '../../lib/actions.js';

    export let event;
    export let isList;
    export let isPast;

    const venue = findItem(event.venue_id, $venues$);
    const venueColor = venue.gutter_color;
    const roomColor = findItem(event.room_id, venue.rooms).gutter_color;

    let evHidden;
    let eventLink = $router.url + `?id=${event.id}`;
    let eventTypeName = findItem(event.event_type_id, $eventTypes$).name;

    $: evHidden = (event.approved_at && event.approved_at != event.date_at);
</script>

<div class:event-hidden={evHidden} class="relative group">
    <div class:ml-2={isList}
         class="ev-{event.status} p-1 text-base"
         style="border-left: 6px solid {venueColor};">

        <A href="{eventLink}">
            {#if isPast}
                {#if event.status == EVENT_STATUS.EVENT_STATUS_APPROVED}
                    <Icon class="inline" size={18} icon="alert-circle" color="aa0000"
                          title="Θα πρέπει να ολοκληρώσετε αυτό το event"/>
                {:else}
                    {#if event.status == EVENT_STATUS.EVENT_STATUS_NEW}
                        <Icon class="inline" size={18} icon="alert-circle" color="aa0000"
                              title="Έχει περάσει η ημερομηνία. Το event δεν θα πρέπει να επιβεβαιωθεί σε αυτή την ημερομηνία"/>
                    {/if}
                {/if}
            {/if}
            {eventTypeName}
        </A>
    </div>
    <div use:ensureInView class="text-base absolute left-0 top-0 hidden z-40 group-hover:block p-1 bg-white -translate-y-full text-nowrap rounded-md border pointer-events-none">
        <span class="font-bold">{eventTypeName}</span><br>
        - {DMY(event.created_at)} {event.hour}<br>
        - {event.guests_nr} άτομα<br><br>
        {#each event.contacts as contact}
            <div>
                &bull; {contact.first_name} {contact.last_name} - {contact.mobile} {contact.phone}
            </div>
        {/each}
    </div>
</div>