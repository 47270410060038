<script>
    import {createEventDispatcher} from "svelte";
    import VenueRoomItem from "./rooms/VenueRoomItem.svelte";
    import Icon from "../layout/Icon.svelte";

    export let venue;
    export let selected;

    let expanded = true;
    const dispatch = createEventDispatcher();

    const toggle = () => expanded = !expanded;
    const editVenue = () => dispatch('editVenue', venue);
    const addRoom = () => dispatch('addRoom', venue);
    const editRoom = ({detail:room}) => dispatch('editRoom', {venue, room});
    const deleteRoom = ({detail:room}) => dispatch('deleteRoom', room);

</script>
<div class="sre-item mb-2 shadow border-t border-t-gray-light bg-bg">
    <div class="border-b border-b-gray-light p-sm flex items-center gap-sm">
        <div class="flex-1 flex gap-sm">
            <span class="shadow inline-block w-6 rounded" style="background-color: {venue.gutter_color}">&nbsp;</span>
            <span class="link" on:click={editVenue}>
            {venue.name} ({venue.code})
            </span>
        </div>

        <button on:click={toggle}>
            {#if expanded}
                <Icon icon="arrow-up-drop-circle-outline"/>
            {:else}
                <Icon icon="arrow-down-drop-circle-outline"/>
            {/if}
        </button>
    </div>

    {#if expanded}
        <div>
            {#if venue.rooms.length > 0}
                {#each venue.rooms as room}
                    <VenueRoomItem
                            {room}
                            {venue}
                            on:edit={editRoom}
                            on:delete={deleteRoom}
                    />
                {/each}
            {/if}
        </div>

        <div class="p-sm bg-white flex items-center gap-sm justify-end">
            <button class="btn px-sm flex gap-2 text-sm" on:click={addRoom}>
                <Icon color="00aa00" icon="plus-circle"/>
                <span>Αίθουσα</span>
            </button>
        </div>
    {/if}
</div>
