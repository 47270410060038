<script>

    import {createEventDispatcher} from "svelte";
    import IconButton from "../../layout/IconButton.svelte";
    import A from "../../../lib/Router/A.svelte";
    import Icon from "../../layout/Icon.svelte";

    export let venue;
    export let room;

    const dispatch = createEventDispatcher();

    const edit = () => dispatch('edit', room);
</script>

<div class="bg-bg1 sre-item p-sm pl-lg flex items-center gap-sm">
    <div class="flex-1 flex gap-sm">
        <span class="shadow inline-block w-6 rounded" style="background-color: {room.gutter_color}">&nbsp;</span>
        <span class="link" on:click={edit}>
            {room.name} ({room.code})
        </span>
    </div>

    <span class="text-gray-light text-sm">{room.min_persons}-{room.max_persons}</span>

    <A href="/app/settings/rooms/{room.id}">
        <Icon icon="hand" color="000000"/>
    </A>
</div>