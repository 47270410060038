import {is_function, noop} from "svelte/internal";

export const focusFirstElement = node => {
    const nodes = node.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );

    if (nodes && nodes[0]) {
        nodes[0].focus();
    }
};

export const trapKey = (node, options) => {
    let key = "Enter";
    let callback = noop();
    let preventDefault = false;

    const parseOptions = opts => {
        if (is_function(opts)) {
            callback = opts;
        } else {
            if (opts.key) {
                key = opts.key;
            }
            if (opts.callback) {
                callback = opts.callback;
            }
            if (opts.preventDefault) {
                preventDefault = opts.preventDefault;
            }
        }
    };

    const trapIt = event => {
        if (event.key === key) {
            if (preventDefault) {
                event.preventDefault();
            }
            callback(node);
            try {
                node.blur();
            } catch (e) {
            }
        }
    };

    parseOptions(options);
    node.addEventListener("keydown", trapIt);

    return {
        update: parseOptions,
        destroy: () => {
            node.removeEventListener("keydown", trapIt);
        },
    };
};


export const ensureInView = (node, options) => {
    const defaults = {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    };

    const parseOptions = opts => {
        if (opts) {
            return {...defaults, ...opts};
        }
        return defaults;
    };

    const moveIntoView = (node, options) => {
        const rect = node.getBoundingClientRect();
        const {top, left, right, bottom} = options;

        if (rect.top < top) {
            node.style.top = `${top}px`;
            node.style.bottom = "auto";
        }
        if (rect.left < left) {
            node.style.left = `${left}px`;
            node.style.right = "auto";
        }
        if (rect.right > window.innerWidth - right) {
            node.style.right = `${right}px`;
            node.style.left = "auto";
        }
        if (rect.bottom > window.innerHeight - bottom) {
            node.style.bottom = `${bottom}px`;
            node.style.top = "auto";
        }
    };

    const opts = parseOptions(options);

    // Create an intersection observer
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                moveIntoView(node, opts);
            }
        });
    }, {
        rootMargin: `${opts.top}px ${opts.right}px ${opts.bottom}px ${opts.left}px`
    });

    // Start observing the node
    observer.observe(node);

    return {
        update: opts => {
            observer.disconnect();
            observer.observe(node);
            moveIntoView(node, parseOptions(opts));
        },
        destroy: () => {
            observer.disconnect();
        }
    };
};