<script>
    import RoomServiceItem from "./RoomServiceItem.svelte";
    import Icon from "../../layout/Icon.svelte";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import ServiceEditor from "../../services/services/ServiceEditor.svelte";
    import {createEventDispatcher, onMount} from "svelte";

    export let category;
    export let allServices;

    let redrCounter = 0;
    let myCount = 0;

    let expanded = false;
    let addServicePopupVisible = false;

    const dispatch = createEventDispatcher();
    const hideAddServiceDialog = () => addServicePopupVisible = false;

    const hideDialogAfterSave = e => {
        expanded = true;
        dispatch('toggle', e.detail);
        hideAddServiceDialog();
    };

    const toggleMe = () => expanded = !expanded;
    const redraw = () => redrCounter++;

    onMount(() => {
        expanded = (myCount>0);
    });

    $: myCount = category.services.reduce((acc, a) => {
        if (allServices.includes(a.id)) {
            return acc+1;
        }
        return acc;
    }, 0);
</script>


<div class="border-b">
    <div class="p-sm pr-lg bg-zinc-50 flex gap-sm items-center shadow-md" on:click={toggleMe}>
        {#if expanded}
            <Icon icon="arrow-up-drop-circle-outline"/>
        {:else}
            <Icon icon="arrow-down-drop-circle-outline"/>
        {/if}
        <span class="flex-1">
            <span class="font-bold">{category.name}</span>
            {#if myCount>0}({myCount}){/if}
        </span>
        <button class="plain" on:click|stopPropagation={() => addServicePopupVisible = true}>
            <Icon color="00aa00" icon="plus-circle"/>
        </button>
    </div>

    {#if expanded}
        {#key redrCounter}
            {#each category.services as service}
                <RoomServiceItem
                        {service}
                        {allServices}
                        on:toggle
                        on:toggle={redraw}
                />
            {/each}
        {/key}
    {/if}
</div>


{#if addServicePopupVisible}
    <ScreenCenter on:close={hideAddServiceDialog} title="Προσθήκη προϊόντος/υπηρεσίας">
        <ServiceEditor
                service=null
                serviceCategory={category}
                on:close={hideAddServiceDialog}
                on:save={hideDialogAfterSave}/>
    </ScreenCenter>
{/if}