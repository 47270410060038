<script>
    import CloseButton from "./CloseButton.svelte";

    export let errors = null;
</script>


{#if errors && errors.length}
    <div class="bg-orange text-white p-xs relative rounded-lg my-sm text-sm">
        <div class="absolute top-0 right-0">
            <CloseButton on:click={() => errors = null}/>
        </div>
        {#each errors as error}
            <div>{error}</div>
        {/each}
    </div>
{/if}