<script>
    import {API, serviceCategories$} from "../../../lib/lib.js";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import ServiceEditor from "../../services/services/ServiceEditor.svelte";
    import RoomServiceCategoryItem from "./RoomServiceCategoryItem.svelte";
    import Icon from "../../layout/Icon.svelte";

    export let id;

    let room;

    let dirty = false;

    let loaded = false;
    let allServices = [];

    let addServicePopupVisible = false;
    let serviceCategory = null;
    let startServices = '';
    let currentServices = '';

    const init = async () => {
        loaded = false;
        const res = await API.get(`room/${id}`);
        room = res.data.name;
        allServices = res.data.services.map(s => s.id).sort((a, b) => a > b);
        startServices = JSON.stringify(allServices);
        currentServices = startServices;
        loaded = true;
    };

    const toggle = ({detail: service}) => {
        dirty = true;
        const index = allServices.indexOf(service.id);

        if (index === -1) {
            allServices = [...allServices, service.id].sort((a, b) => a > b);
        } else {
            allServices.splice(index, 1);
        }
        currentServices = JSON.stringify(allServices);

        dirty = currentServices !== startServices;
    };

    const save = async () => {
        const res = await API.post(`room/${id}/services`, {
            service_ids: allServices
        });

        dirty = false;
        showMessage('Η αποθήκευση έγινε');
    };

    const hideAddServiceDialog = () => {
        serviceCategory = null;
        addServicePopupVisible = false;
    };

    init();
</script>


{#if loaded}
    <div class="sticky top-12 bg-white px-md py-2 text-sm border-b shadow-md flex">
        <span class="font-bold flex-1">{room}</span>
        {#if dirty}
            <button on:click={save} class="btn btn-ok pl-lg pr-sm py-0 text-white flex items-center gap-sm">
                Αποθήκευση
                <Icon icon="content-save" color="ffffff" size="16" />
            </button>
        {/if}
    </div>
    {#each $serviceCategories$ as sc}
        <RoomServiceCategoryItem
                category={sc}
                {allServices}
                on:toggle={toggle}
        />
    {/each}

{/if}


{#if addServicePopupVisible}
    <ScreenCenter on:close={hideAddServiceDialog} title="Προσθήκη προϊόντος/υπηρεσίας">
        <ServiceEditor
                service=null
                {serviceCategory}
                on:close={hideAddServiceDialog}
                on:save={hideAddServiceDialog}/>
    </ScreenCenter>
{/if}
