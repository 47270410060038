<script>
    import IconButton from "../../layout/IconButton.svelte";
    import {API} from "../../../lib/lib.js";
    import {createEventDispatcher} from "svelte";
    import {trapKey} from "../../../lib/actions.js";
    import NoteEditor from "../../NoteEditor.svelte";

    export let contact;
    export let event;
    export let readOnly = false;

    let noteTexts = contact.notes.map(x => x.body);

    let isDirty = false;
    let initialTitle = contact.pivot.title;

    const dispatch = createEventDispatcher();

    let notesVisible = false;

    const saveContact = async () => {
        const res = await API.patch(`event/${event.id}/contact/${contact.id}`, {title: contact.pivot.title});

        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        showMessage('Η επαφή ενημερώθηκε.');
        initialTitle = contact.pivot.title
    };

    const saveNotes = async () => {
        const res = await API.post(`contact/${contact.id}/notes`, {notes: noteTexts});

        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        showMessage('Οι σημειώσεις αποθηκεύτηκαν');
    };

    const deleteContact = async () => {
        if (confirm("Είσαστε σίγουροι")) {
            const res = await API.delete(`event/${event.id}/contact/${contact.id}`);
            if (res.error) {
                showMessage('Κάτι δεν πήγε καλά', 'bg-red');
                return;
            }

            event.contacts = event.contacts.filter(n => n.id != contact.id);
            showMessage('Η επαφή αφαιρέθηκε.');
            dispatch('delete');
        }
    };

    const toggleNotes = () => {
        notesVisible = !notesVisible;
    };

    $: isDirty = (contact.pivot.title != initialTitle)
</script>

<div class="mb-1">
    <div class="flex gap-sm p-1 bg-white text-sm items-center">
        {#if !readOnly}
        <IconButton title="Διαγραφή επαφής" color="ff0000" button="minus-circle"
                    on:click={() => deleteContact(contact)}/>
            {/if}

        <div class="flex-1 flex gap-sm items-center">
            <div class="flex-1">
                {contact.first_name} {contact.last_name}
            </div>
            <input
                    use:trapKey={saveContact}
                    type="text"
                    class="w-28 font-thin text-xs"
                    class:!bg-yellow={isDirty}
                    bind:value={contact.pivot.title}>
        </div>

        <div class="flex text-xs items-center">
            <IconButton
                    title="εμφάνιση σημειώσεων"
                    color="00aa00"
                    button="comment-account"
                    on:click={toggleNotes}/>
            {contact.notes.length}
        </div>
    </div>
    {#if notesVisible}
        <div class="mb-sm p-sm bg-white text-sm">
            <NoteEditor bind:notes={noteTexts}/>
            <div class="text-right">
                <button class="btn btn-gray text-xs"
                        title="Αποθήκευση"
                        on:click={saveNotes}>Αποθήκευση σημειώσεων
                </button>
            </div>
        </div>
    {/if}
</div>