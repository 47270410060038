<script>
    import {API, copyObj, extraData$, serviceCategories$} from "../../lib/lib.js";
    import {createEventDispatcher} from "svelte";
    import Icon from "../layout/Icon.svelte";
    import {focusFirstElement, trapKey} from "../../lib/actions.js";
    import PairEditor from "../varius/PairEditor.svelte";
    import EventContractDataServiceItem from "./EventContractDataServiceItem.svelte";
    import IconButton from "../layout/IconButton.svelte";

    export let event;
    export let venue;

    const contract = copyObj(event.contract || {
        place: venue.contract.place,
        company: venue.contract.company,
        name: venue.contract.name,
        adt: venue.contract.adt,
        father_name: venue.contract.father_name,
        mother_name: venue.contract.mother_name,
        afm: venue.contract.afm,
        doy: venue.contract.doy,
        address: venue.contract.address,
        printed_at: false
    });
    contract.contacts = contract.contacts || event.contacts.map(c => {
        return {
            name: `${c.last_name} ${c.first_name}`,
            afm: '',
            doy: '',
            address: '',
            selected: true
        }
    });


    contract.extralines = contract.extralines || ['Στο τέλος της βραδιάς θα γίνει απολογισμός και με την έκδοση του τιμολογίου θα γίνει και η εξόφληση του', 'Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΔΕΣΜΕΥΕΤΑΙ ΓΙΑ ΤΟΝ ΑΡΙΘΜΟ ΤΩΝ ΠΡΟΣΚΕΚΛΗΜΕΝΩΝ', '', ''];

    contract.services = contract.services || $extraData$.flatMap(item => {
        return (item.text.length > 0) ? {
            selected: false,
            name: item.text,
            price: item.default
        } : false;
    });

    let cnt = 0;
    let opened = {
        them: true
    };

    const dispatch = createEventDispatcher();
    const redraw = () => cnt++;

    const toggle = id => () => opened[id] = opened[id] ? false : true;
    const toggleAll = () => Object.keys(opened).map(k => opened[k] = false);
    const untoggleAll = () => Object.keys(opened).map(k => opened[k] = true);

    const toggleContact = idx => () => contract.contacts[idx].selected = !contract.contacts[idx].selected;


    const saveContract = async () => {
        if (contract.contacts.filter(c => c.selected).length < 1) {
            alert('Πρέπει να επιλέξετε τουλάχιστον ένα συμβαλλόμενο');
            return;
        }

        const res = await API.post(`event/${event.id}/contract`, {contract});
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.contract = contract;
        showMessage('Το συμφωνητικό αποθηκεύτηκε');
        dispatch('close');
    };
</script>

<div class="text-sm">
    <div class="overflow-y-auto max-h-[560px] text-sm" use:focusFirstElement>
        {#key cnt}
            <div class="font-thin mb-2">
                <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
                     on:click={toggle('me')}>
                    <button class="plain">
                        {#if opened['me']}
                            <Icon icon="arrow-up-drop-circle-outline"/>
                        {:else}
                            <Icon icon="arrow-down-drop-circle-outline"/>
                        {/if}
                    </button>
                    Στοιχεία εταιρίας
                </div>

                <div class:hidden={!opened['me']} class="p-1">
                    <PairEditor title="Μέρος υπογραφής συμβολαίου">
                        <input type="text"
                               bind:value={contract.place}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="Εταιρία">
                        <input type="text"
                               bind:value={contract.company}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="Όνομα">
                        <input type="text"
                               bind:value={contract.name}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="Α.Δ.Τ.">
                        <input type="text"
                               bind:value={contract.adt}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="Όνομα πατρός (του)">
                        <input type="text"
                               bind:value={contract.father_name}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="Όνομα μητρός (της)">
                        <input type="text"
                               bind:value={contract.mother_name}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="ΑΦΜ">
                        <input type="text"
                               bind:value={contract.afm}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="ΔΟΥ">
                        <input type="text"
                               bind:value={contract.doy}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>

                    <PairEditor title="Διεύθυνση">
                        <input type="text"
                               bind:value={contract.address}
                               use:trapKey={{callback: saveContract}}>
                    </PairEditor>
                </div>
            </div>

            <div class="font-thin mb-2">
                <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
                     on:click={toggle('them')}>
                    <button class="plain">
                        {#if opened['them']}
                            <Icon icon="arrow-up-drop-circle-outline"/>
                        {:else}
                            <Icon icon="arrow-down-drop-circle-outline"/>
                        {/if}
                    </button>
                    Στοιχεία συμβαλλόμενου
                </div>
                <div class:hidden={!opened['them']} class="p-1">
                    {#each contract.contacts as contact, idx (idx)}
                        {@const isSelected = contact.selected}
                        <div class:text-gray-light={!contact.selected}
                             class="bg-white flex items-start gap-sm p-2 pb-xl mb-2">
                            <div class=pt-2>
                                <IconButton
                                        on:click={toggleContact(idx)}
                                        button={isSelected ? 'check-circle' : 'circle-outline'}
                                        color={isSelected ? '00aa00':'cccccc'}/>
                            </div>
                            <div class="flex-1">
                                <PairEditor title="Όνομα">
                                    <input type="text" bind:value={contact.name}/>
                                </PairEditor>

                                {#if contact.selected}
                                    <PairEditor title="ΑΦΜ">
                                        <input type="text" bind:value={contact.afm}/>
                                    </PairEditor>

                                    <PairEditor title="ΔΟΥ">
                                        <input type="text" bind:value={contact.doy}/>
                                    </PairEditor>

                                    <PairEditor title="Διεύθυνση">
                                        <input type="text" bind:value={contact.address}/>
                                    </PairEditor>
                                {/if}
                            </div>
                        </div>
                    {/each}
                </div>
            </div>


            <div class="font-thin mb-2">
                <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
                     on:click={toggle('services')}>
                    <button class="plain">
                        {#if opened['services']}
                            <Icon icon="arrow-up-drop-circle-outline"/>
                        {:else}
                            <Icon icon="arrow-down-drop-circle-outline"/>
                        {/if}
                    </button>
                    Έξτρα όροι:
                </div>
                <div class:hidden={!opened['services']} class="p-1">
                    {#each contract.extralines as line, idx}
                        <label class="py-1 block">
                            <input type="text" bind:value={contract.extralines[idx]} placeholder="Γραμμή #{idx+1}"/>
                        </label>
                    {/each}
                </div>
            </div>

            <div class="font-thin mb-2">
                <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
                     on:click={toggle('lines')}>
                    <button class="plain">
                        {#if opened['lines']}
                            <Icon icon="arrow-up-drop-circle-outline"/>
                        {:else}
                            <Icon icon="arrow-down-drop-circle-outline"/>
                        {/if}
                    </button>
                    Υπηρεσίες:
                </div>
                <div class:hidden={!opened['lines']} class="p-1">
                    {#each contract.services as service}
                        <EventContractDataServiceItem bind:service/>
                    {/each}
                </div>
            </div>
        {/key}
    </div>

    <div class="text-right p-1">
        <span class="link" on:click={toggleAll}>Κλείσε όλα</span> |
        <span class="link" on:click={untoggleAll}>Ανοιξε όλα</span>
    </div>
    <hr>

    <div class="text-right">
        <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
        <button class="btn btn-ok" on:click={saveContract}>Αποθήκευση</button>
    </div>
</div>