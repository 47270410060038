<script>
    import {contacts$, dDMYhm, findItem} from "../../../lib/lib.js";

    export let payment;
    export let event;
    export let readOnly = false;


    const contact = findItem(payment.contact_id, $contacts$);
    const name = `${contact.first_name} ${contact.last_name}`;
</script>


<div class="flex items-center gap-xs" on:click>
    <div class="flex-1">{name}</div>
    <div class="text-gray w-32">{dDMYhm(new Date(payment.created_at))}</div>
    <div class="text-right w-20" class:link={!readOnly}>{payment.amount}€</div>
</div>