<script>
    import IconButton from "../layout/IconButton.svelte";
    import {API} from "../../lib/lib.js";
    import {createEventDispatcher} from "svelte";

    export let text;
    export let entity;
    export let endpoint = 'serviceCategory';

    const dispatch = createEventDispatcher();

    text.id = text.id || 0;
    text.body = text.body || '';

    let initialBody = text.body;
    let isDirty = false;

    const saveNote = async () => {
        const res = await API.patch(`${endpoint}/${entity.id}/text/${text.id}`, {body: text.body});

        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        entity.texts.map(n => {
            if (n.id == text.id) {
                n.body = text.body;
            }
        });

        showMessage('Το κείμενο ενημερώθηκε');
        initialBody = text.body;
    };

    const deleteNote = async () => {
        if (!text.id) {
            entity.texts = entity.texts.filter(n => n != text);
            showMessage('Το κείμενο διαγράφηκε');
            dispatch('delete');
            return;
        }
        if (confirm("Είσαστε σίγουροι")) {
            const res = await API.delete(`${endpoint}/${entity.id}/text/${text.id}`);
            if (res.error) {
                showMessage('Κάτι δεν πήγε καλά', 'bg-red');
                return;
            }

            entity.texts = entity.texts.filter(n => n.id != text.id);
            showMessage('Το κείμενο διαγράφηκε');
            dispatch('delete');
        }
    };

    $: isDirty = (text.body != initialBody)
</script>


<div class="flex items-start gap-sm p-1 bg-white mb-1 text-sm">
    <IconButton color="ff0000" button="minus-circle" on:click={deleteNote}/>
    <textarea bind:value={text.body} class:!bg-yellow={isDirty}></textarea>
    <IconButton color="00aa00" button="save" on:click={saveNote}/>
</div>