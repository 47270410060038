<script>
    import IconButton from '../layout/IconButton.svelte';
    import CalendarDayEvents from './CalendarDayEvents.svelte';
    import {nameOfDays} from '../../lib/lib.js';
    import {getDay, getDaysInMonth} from 'date-fns/fp';

    let emptyStart = 0;
    let isList = false;
    let showAll = false;

    export let date;
    export let events = [];

    let numDays = 0;
    let loaded = false;
    const now = new Date();

    const names = nameOfDays();
    names.push(names.shift());

    const refreshCalendar = date => {
        emptyStart = getDay(date)-1;
        if (emptyStart == -1) {
            emptyStart = 6
        }
        numDays = getDaysInMonth(date);
        loaded = true;
    };

    const toggleList = () => {
        isList = !isList;
    };


    $: refreshCalendar(date);
</script>

{#if (loaded)}
    <div class="flex py-2 mt-2">
        <h2 class="m-0 flex-1 ">
            Ημερολόγιο
        </h2>
        <div class="flex gap-xs">
            <IconButton button="eye"
                        on:click={() => showAll = !showAll}
                        color={showAll ? '00aa00' : '777777'}
            />
            <div class="flex text-xs ">
                <button class="btn" class:btn-normal={isList} on:click={toggleList}>Λιστα</button>
                <button class="btn" class:btn-normal={!isList} on:click={toggleList}>Ημερολόγιο</button>
            </div>
        </div>
    </div>

    <div class="grid-cols-7"
         class:show-all={showAll}
         class:grid={!isList}>
        {#if !isList}
            {#each names as day}
                <div class="bg-black text-white text-xs font-bold py-2 p-1">
                    {day}
                </div>
            {/each}

            {#each Array(emptyStart) as day, _idx}
                <div></div>
            {/each}
        {/if}
        {#each Array(numDays) as day, _idx}
            <CalendarDayEvents
                    {isList}
                    {date}
                    day={_idx+1}
                    events={events[_idx+1]}
            />
        {/each}
    </div>
{/if}
<!--  -->