<script>
    import {API, copyObj, serviceCategories$} from "../../lib/lib.js";
    import {createEventDispatcher} from "svelte";
    import Icon from "../layout/Icon.svelte";
    import IconButton from "../layout/IconButton.svelte";

    export let room;
    export let event;

    const services = copyObj(event.services || {
        builtin: [],
        extra: []
    });

    services.builtin = services.builtin || [];
    services.extra = services.extra || [];

    let cnt = 0;
    let opened = {};

    const dispatch = createEventDispatcher();
    const myServices = new Map();
    const extra = {};


    services.builtin.map(service => {
        myServices.set(service.id, {
            id: service.id,
            cat_id: service.cat_id
        });
    });
    $serviceCategories$.map(sc => {
        opened[sc.id] = true;
        extra[sc.id] = [{
            cat_id: sc.id,
            name: ''
        }, {
            cat_id: sc.id,
            name: ''
        }];
    });
    services.extra.map(service => {
        extra[service.cat_id] = [{
            cat_id: service.cat_id,
            name: service.name
        }, ...extra[service.cat_id]];
    });


    const toggle = id => () => opened[id] = opened[id] ? false : true;
    const toggleAll = () => Object.keys(opened).map(k => opened[k] = false);
    const untoggleAll = () => Object.keys(opened).map(k => opened[k] = true);
    const toggleService = (id, cat_id) => () => {
        if (myServices.has(id)) {
            myServices.delete(id);
        } else {
            myServices.set(id, {id, cat_id});
        }
        redraw();
    };

    const addToCat = id => {
        extra[id] = [...extra[id], {
            cat_id: id,
            name: ''
        }];
    };
    const redraw = () => cnt++;

    const includedServicesIds = room.services.map(service => service.id);

    const save = async ({detail: data}) => {
        const services = {
            builtin: [],
            extra: []
        };

        Object.keys(extra).map(key => {
            extra[key].map(item => {
                if (item.name.length > 0) {
                    services.extra.push(item);
                }
            });
        });

        [...myServices.keys()].map(key => {
            services.builtin.push(myServices.get(key));
        });

        const res = await API.post(`event/${event.id}/services`, {services});
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.services = services;
        showMessage('Οι παροχές αποθηκευτηκαν');
        dispatch('close');
    };

</script>

<div class="text-sm">
    <div class="overflow-y-auto max-h-[560px] text-sm">
        {#key cnt}
            {#each $serviceCategories$ as category (category.id)}
                <div class="font-thin border border-zinc-200 bg-gray-light mb-2 shadow">
                    <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
                         on:click={toggle(category.id)}>
                        <button class="plain">
                            {#if opened[category.id]}
                                <Icon icon="arrow-up-drop-circle-outline"/>
                            {:else}
                                <Icon icon="arrow-down-drop-circle-outline"/>
                            {/if}
                        </button>
                        {category.name}
                    </div>

                    <div class:hidden={!opened[category.id]} class="p-1">
                        {#each category.services.filter(c => c.is_active) as service (service.id)}
                            <div>
                                {#if includedServicesIds.indexOf(service.id) > -1}
                                    <div class="bg-white py-1 m-1 p-1 flex items-center gap-sm rounded border-zinc-200 border"
                                         on:click={toggleService(service.id, category.id)}>
                                        <div class="">
                                            <IconButton size={24} button="check-circle"
                                                        color={myServices.has(service.id) ? '00aa00':'cccccc'}/>
                                        </div>
                                        <div class="flex-1">{@html service.name}</div>
                                    </div>
                                {/if}
                            </div>
                        {/each}
                        {#each (extra[category.id] || []) as extra}
                            <div class="bg-white py-1 m-1 p-1 flex items-center gap-sm rounded border-zinc-200 border">
                                <input type="text" bind:value={extra.name}>
                            </div>
                        {/each}
                        <div class="text-right px-2 flex items-center">
                            <div class="flex-1 bold">&nbsp;</div>
                            <IconButton button="plus-circle" color="00aa00" on:click={() => addToCat(category.id)}/>
                        </div>
                    </div>
                </div>
            {/each}
        {/key}
    </div>

    <div class="text-right p-1">
        <span class="link" on:click={toggleAll}>Κλείσε όλα</span> |
        <span class="link" on:click={untoggleAll}>Ανοιξε όλα</span>
    </div>
    <hr>

    <div class="text-right">
        <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
        <button class="btn btn-ok" on:click={save}>Αποθήκευση</button>
    </div>
</div>