<script>
    import Icon from "../layout/Icon.svelte";
    import {createEventDispatcher} from "svelte";

    export let category;
    export let service;

    const dispatch = createEventDispatcher();
    const editService = () => dispatch('editService', {service, category});
    const deleteService = () => dispatch('deleteService', service);
</script>


<div class="bg-bg1 sre-item py-1 p-sm pl-lg flex items-center gap-sm">
    <div class="flex-1 flex gap-sm" class:text-gray={!service.is_active}>
        {@html service.name}
    </div>

    <button class="plain" on:click={deleteService}>
        <Icon icon="trash-can" color="ff0000"/>
    </button>

    <button class="plain" on:click={editService}>
        <Icon icon="pencil-circle" color="000000"/>
    </button>

    <!--{#if service.is_active}-->
    <!--    <Icon on:click={toggle} icon="eye-circle" color="00aa00"/>-->
    <!--{:else}-->
    <!--    <Icon on:click={toggle} icon="eye-circle" color="aa0000"/>-->
    <!--{/if}-->
</div>