<script>
    import {onDestroy, onMount} from 'svelte';
    import {fade, fly} from 'svelte/transition';
    import {backOut} from 'svelte/easing';

    let toasts = [];
    let defaultDelay = 4000;

    let toastId = 0;

    const pushToast = (msg = '', className = null, delay = null) => {
        delay = delay || defaultDelay;
        className = className || 'normal';

        const newToast = {
            _id: ++toastId,
            visible: true,
            className,
            msg
        };
        newToast.tm = setTimeout(() => {
            delToast(newToast);
        }, delay);
        toasts = [...toasts, newToast];
    };

    const delToast = toast => {
        clearTimeout(toast.tm);
        toasts = toasts.filter(t => t != toast);
    };

    onMount(() => {
        globalThis.showMessage = pushToast;
    });

    onDestroy(() => {
        delete globalThis.showMessage;
    });

</script>

<div class="toast-wrapper">
    {#each toasts as toast (toast._id)}
        {#if toast.visible}
            <div class="toast-item {toast.className}"
                 in:fly="{{delay: 0, duration: 300, x: 300, y: 0, opacity: 0.1, easing: backOut}}"
                 out:fade on:click={() => toast.visible = false}>
                {@html toast.msg}
            </div>
        {/if}
    {/each}
</div>



<style>
    .toast-wrapper {
        position: fixed;
        right: 10px;
        top: 0;
        z-index: 9999;
    }

    .toast-item {
        border-radius: 4px;
        padding: 8px 10px;
        margin-top: 10px;
        max-width: 400px;
        color: #fff;
        @apply text-xs
    }

    .success {
        color: #fff;
        background: green;
    }

    .danger {
        color: #fff;
        background: red;
    }

    .normal, .primary {
        color: #fff;
        background: #2196f3;
    }

    .transparent {
        background: #fff0;
        color: #000;
    }

    .aslink {
        background: #fff0;
        color: var(--link-color);
        border: 1px solid #0000;
    }
</style>
