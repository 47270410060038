<script>
    import IconButton from "./layout/IconButton.svelte";
    import {createEventDispatcher} from "svelte";

    export let notes = [];
    const dispatch = createEventDispatcher();

    const close = () => dispatch('close');

    const addNote = () => {
        notes = [...notes, ''];
    };

    const remove = idx => {
        notes.splice(idx, 1);
        notes = notes;
    };

    if (notes.length == 0) {
        addNote()
    }
</script>


<h4 class="mb-1 flex text-sm">
    <div class="flex-1">Σημειώσεις</div>
    <IconButton color="00aa00" button="plus-circle" on:click={addNote}/>
</h4>
<div class="text-sm">
    {#each notes as note, _idx}
        <div class="flex gap-sm items-start">
            <textarea bind:value={notes[_idx]} class="block mb-2 text-xs" placeholder="Σημείωση #{_idx+1}"></textarea>
            {#if _idx > 0}
                <IconButton button="minus-circle" color="FF0000" bg="ffff" on:click={() => remove(_idx)}/>
            {/if}
        </div>
    {/each}
</div>