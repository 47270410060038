<script>
    import {API, eventTypes$, terms$, venues$} from "../../../lib/lib.js";
    import ErrorDisplay from "../../layout/ErrorDisplay.svelte";
    import {focusFirstElement, trapKey} from "../../../lib/actions.js";
    import {createEventDispatcher} from "svelte";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import TypeEditor from "../../types/TypeEditor.svelte";
    import SquareButton from "../../layout/SquareButton.svelte";
    import Spacer from "../../layout/Spacer.svelte";

    export let venue = {};
    export let room = {};

    const dispatch = createEventDispatcher();
    let typeEditorPopupVisible = false;
    let type = null;

    let errors = null;

    const roomCopy = {
        venue_id: room.venue_id || venue.id,
        min_persons: room.min_persons || 100,
        max_persons: room.max_persons || 2000,
        name: room.name || '',
        code: room.code || '',
        gutter_color: room.gutter_color || '#9999ff',
        event_types: room.event_types?.map(et => et.id) || [],
        terms: room.terms?.map(et => et.id) || [],
    };

    const resetRoomForm = () => {
        roomCopy.venue_id = venue.id || 0;
        roomCopy.min_person = 100;
        roomCopy.max_persons = 2000;
        roomCopy.name = '';
        roomCopy.code = '';
        roomCopy.gutter_color = '#9999ff';
        roomCopy.event_types = [];
        roomCopy.terms = [];
    };

    const saveRoom = () => {
        errors = null;

        if (room.id) {
            updateRoom();
        } else {
            createNewRoom();
        }
    };

    const createNewRoom = async () => {
        const res = await API.post(`room`, roomCopy);
        if (res.error == true) {
            errors = res.data;
            return;
        }

        venues$.update(store => {
            store.map(venue => {
                if (venue.id == venue.id) {
                    venue.rooms = [...venue.rooms, res.data];
                }
            });
            return store;
        });

        resetRoomForm();

        showMessage('Η αίθουσα δημιουργήθηκε');
        dispatch('save', res.data);
    };

    const updateRoom = async () => {
        const res = await API.patch(`room/${room.id}`, roomCopy);
        if (res.error == true) {
            errors = res.data;
            return;
        }

        venues$.update(store => {
            store.map(v => {
                if (v.id == venue.id) {
                    v.rooms.map(theRoom => {
                        if (room.id == theRoom.id) {
                            Object.assign(theRoom, res.data);
                        }
                    });
                }
            });
            return store;
        });


        showMessage('H αίθουσα αποθηκεύτηκε');
        dispatch('save', res.data);
    };

    const showEditTypePopup = (t = null) => {
        type = t;
        typeEditorPopupVisible = true;
    };

    const hideEditTypePopup = (t = null) => {
        type = null;
        typeEditorPopupVisible = false;
    };

</script>

<div class="grid xs:grid-cols-3 gap-md">
    <div class="col-span-2">
        <h4>Όνομα</h4>
        <input type="text" bind:value={roomCopy.name} use:trapKey={{callback: saveRoom}}>
    </div>
    <div>
        <h4>Κωδικός</h4>
        <input type="text" bind:value={roomCopy.code} use:trapKey={{callback: saveRoom}}>
    </div>
</div>


<Spacer/>
<h4>Τύποι εκδηλώσεων</h4>
<div class="mb-2">
    {#each $eventTypes$ as et}
        <div class="sre-item flex p-1">
            <label class="flex-1">
                <input type="checkbox" value={et.id} bind:group={roomCopy.event_types}>
                {et.name}
            </label>
            <SquareButton button="edit" on:click={() => showEditTypePopup(et)}/>
        </div>
    {/each}
</div>
<Spacer/>
<div class="mb-2">
    <h4>Όροι</h4>
    {#each $terms$ as et}
        <div class="sre-item flex p-1">
            <label class="flex-1">
                <input type="checkbox" value={et.id} bind:group={roomCopy.terms}>
                {et.name}
            </label>
            <!--            <SquareButton button="edit" on:click={() => showEditTypePopup(et)}/>-->
        </div>
    {/each}
</div>

<Spacer/>

<div class="grid xs:grid-cols-4 gap-md">
    <div>
        <h4>Χρώμα</h4>
        <input type="color" bind:value={roomCopy.gutter_color}>
    </div>
    <div class="col-span-3">
        <h4>Χωρητικότητα (από - έως)</h4>
        <div class="grid xs:grid-cols-2 gap-md">
            <input class="" type="number" bind:value={roomCopy.min_persons}>
            <input class="" type="number" bind:value={roomCopy.max_persons}>
        </div>
    </div>
</div>


<ErrorDisplay bind:errors/>
<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveRoom}>Αποθήκευση</button>
</div>


{#if typeEditorPopupVisible}
    <ScreenCenter title="Νέος τύπος εκδήλωσης" on:close={hideEditTypePopup}>
        <TypeEditor {type} on:close={hideEditTypePopup} on:save={hideEditTypePopup}/>
    </ScreenCenter>
{/if}