<script>
    import {API, dDMYhm, EVENT_STATUS} from "../../../lib/lib.js";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import EventTaeData from "../../papers/EventTaeData.svelte";

    export let event;
    export let venue;

    let popupVisible = false;

    const showTAEPopup = () => popupVisible = true;
    const hideTAEPopup = () => popupVisible = false;

    const toggleAndPrintTAE = async e => {
        await toggleTaePrint(true);
        window.open(`/print/${event.id}/tae`);
    };

    const toggleTaePrint = async e => {
        if (!confirm("Το συμφωνητικό θα αλλάξει κατάσταση εκτύπωσης.\n\nΕίσαστε σίγουροι;")) {
            return;
        }

        let toSend = {};
        if (e === true) {
            toSend = {state: true}
        }

        const res = await API.patch(`event/${event.id}/tae`, toSend);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.tae.printed_at = res.data.printed_at;

        showMessage(`Η κατάσταση άλλαξε σε εκτυπωμένο`);
    };
</script>

<div class="mb-2">
    <div class="font-bold">

        {#if event.status != EVENT_STATUS.EVENT_STATUS_CANCELED}
            <span class="underline link" on:click={showTAEPopup}>Εντυπο ΤΑΕ</span>
        {:else}
            Εντυπο ΤΑΕ
        {/if}
    </div>

    <div class="p-1 mb-1">
        {#if event.tae?.printed_at}
            <span class="text-green">
                Εκτυπώθηκε: {dDMYhm(event.tae.printed_at)}</span>
            <span class="link" on:click={toggleAndPrintTAE}>Εκτύπωση</span>
        {:else}
            {#if event.tae == null}
                <span class="text-red">Δεν έχει δημιουργηθεί Εντυπο ΤΑΕ.</span>
            {:else}
                Δεν εχει εκτυπωθεί Εντυπο ΤΑΕ.
                <span class="link" on:click={toggleAndPrintTAE}>Εκτύπωση</span>
            {/if}
        {/if}
    </div>
</div>


{#if popupVisible}
    <ScreenCenter title="Εντυπο ΤΑΕ" on:close={hideTAEPopup}>
        <EventTaeData
                {event}
                {venue}
                on:close={hideTAEPopup}
        />
    </ScreenCenter>
{/if}
