<script>
    import Route from "./lib/Router/Router.svelte";
    import Toast from "./components/layout/Toast.svelte";
    import Settings from "./components/Settings/Settings.svelte";
    import CalendarView from "./components/Ev/CalendarView.svelte";
    import EventEditor from "./components/Ev/EventEditor.svelte";
    import ContactsPanel from "./components/contacts/ContactsPanel.svelte";
    import ContactListing from "./components/contacts/ContactListing.svelte";
    import ContactDetails from "./components/contacts/ContactDetails.svelte";
</script>


<div class="flex-1 h-full overflow-scroll md:ml-[240px] moves min-w-[320px]">
    <Route match="settings">
        <Settings/>
    </Route>

    <Route match="events">
        <CalendarView/>
    </Route>

    <Route match="contact">
        <Route exact>
            <ContactListing />
        </Route>
        <Route match="*" let:router>
            <ContactDetails id={router.star}/>
        </Route>
    </Route>

    <Route match="new-event">
        <Route exact>
            <EventEditor/>
        </Route>

        <Route match="*" let:router>
            <EventEditor date={router.star}/>
        </Route>
    </Route>
</div>


<ContactsPanel/>
<Toast/>