<script>
    import {API, dateToYYMM, EVENT_STATUS} from '../../lib/lib.js';
    import ScreenCenter from '../ScreenCenter.svelte';
    import EventQuickView from './EventQuickView.svelte';
    import {router} from '../../lib/Router/Router.svelte';
    import MonthPicker from './MonthPicker.svelte';
    import Calendar from './Calendar.svelte';


    export let year;
    export let month;

    let events;

    let calendarDay;
    let selectedEventId;

    let loaded = false;

    const [currentYear, currentMonth] = dateToYYMM().split('-');
    year = year || currentYear;
    month = month || currentMonth;

    const monthChange = ({detail: spec}) => {
        ({year, month} = spec);
    };

    const loadEvent = async id => {
        if (undefined === id) {
            selectedEventId = null;
            return;
        }
        if (!id && selectedEventId) {
            return;
        }
        selectedEventId = id;
    };

    const loadMonthEvents = async (y, m) => {
        const result = await API.get(`event/${y}-${m}/list`);
        if (result.error) {
            showMessage('Κάποιο λάθος συνέβη', 'bg-red');
            return;
        }
        events = [];

        result.data.map(e => {

            const key = Number(e.date_at.split('-')[2]);
            if (!events[key]) {
                events[key] = [];
            }
            const toAdd = {
                ...e.event,
                date_at: e.date_at,
                hour: e.hour
            };
            events[key].push(toAdd);
        });

        loaded = true;
    };

    const determineEventAction = ({detail: spec}) => {
        let [y, m, d] = spec.date.split('-');
        m = Number(m);
        d = Number(d);
        if (y != year || m != month) {
            return;
        }

        if (spec.action == 'add') {
            if (!events[d]) {
                events[d] = [];
            }

            events[d].push(spec.event);
        }

        if (spec.action == 'fix') {
            events.map(allEvents => allEvents.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_APPROVED;
                    event.approved_at = spec.date;
                    event.completed_at = null;
                    event.canceled_at = null;
                }
            }));
        }

        if (spec.action == 'delete') {
            events[d] = events[d].filter(event => event.id != spec.event_id);
        }

        if (spec.action == 'unfix') {
            events.map(allEvents => allEvents.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_NEW;
                    event.approved_at = null;
                    event.completed_at = null;
                    event.canceled_at = null;
                }
            }));
        }

        if (spec.action == 'cancel') {
            events.map(allEvents => allEvents.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_CANCELED;
                    event.approved_at = null;
                    event.completed_at = null;
                    event.canceled_at = spec.cancel_time;
                }
            }));
        }

        if (spec.action == 'uncancel') {
            events.map(allEvents => allEvents.map(event => {
                if (event.id == spec.event_id) {
                    event.status = EVENT_STATUS.EVENT_STATUS_NEW;
                    event.approved_at = null;
                    event.completed_at = null;
                    event.canceled_at = null;
                }
            }));
        }

        events = events;
    };

    $: loadEvent($router.query.id);
    $: calendarDay = new Date(`${year}-${String(month).padStart(2, 0)}-01`);
    $: loadMonthEvents(year, month);
</script>

<div class="p-2 sticky top-0 bg-white shadow-lg border-b border-zinc-300  sm:-mx-sm sm:-mt-sm z-10">
    <MonthPicker {year} {month} on:change={monthChange}/>
</div>


{#if loaded}
    <Calendar date={calendarDay} {events}/>
{/if}


{#if selectedEventId}
    <ScreenCenter title="Λεπτομέρειες">
        <EventQuickView
                on:event:change={determineEventAction}
                on:close={() => selectedEventId = null}
                id={selectedEventId}/>
    </ScreenCenter>
{/if}

<!--
on:dateChange={determineEventAction} on:reset={() => loadedEvent = null}
-->
<style>
    :global(.event-hidden) {
        display: none;
    }

    .show-all :global(.event-hidden) {
        display: block;
        opacity: .3;
    }
</style>