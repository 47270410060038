<script>
    import {API, copyObj, serviceCategories$} from "../../lib/lib.js";
    import {createEventDispatcher} from "svelte";
    import Icon from "../layout/Icon.svelte";
    import {focusFirstElement, trapKey} from "../../lib/actions.js";
    import PairEditor from "../varius/PairEditor.svelte";

    export let event;
    export let venue;

    const tae = copyObj(event.tae || {
        police: venue.tae.police || '',
        merchant: venue.tae.merchant || '',
        animals: [
            {name: 'ΖΥΓΟΥΡΙ', count: 0},
            {name: 'ΓΟΥΡΟΥΝΟΠΟΥΛΟ', count: 0},
            {name: 'ΑΡΝΑΚΙ ΓΑΛΑΚΤΟΣ', count: 0},
            {name: '', count: "0"},
            {name: '', count: "0"},
            {name: '', count: "0"}
        ]
    });
    tae.contacts = tae.contacts || event.contacts.map(c => {
        return {
            name: `${c.last_name} ${c.first_name}`,
            fathers_name: '',
            adt: '',
            dob: '',
            job: '',
            address: '',
            selected: true
        };
    });
    let opened = {
        generic: true
    };

    const toggle = id => () => opened[id] = opened[id] ? false : true;
    const toggleAll = () => Object.keys(opened).map(k => opened[k] = false);
    const untoggleAll = () => Object.keys(opened).map(k => opened[k] = true);
    const removeContact = idx => () => tae.contacts[idx].selected = false;
    const unremoveContact = idx => () => tae.contacts[idx].selected = true;

    const dispatch = createEventDispatcher();
    const redraw = () => cnt++;


    const saveTae = async () => {
        if (tae.contacts.filter(c => c.selected).length < 1) {
            alert('Πρέπει να επιλέξετε τουλάχιστον ένα συμβαλλόμενο');
            return;
        }

        const res = await API.post(`event/${event.id}/tae`, {tae});
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.tae = tae;
        showMessage('Το έντυπο ΤΑΕ αποθηκεύτηκε');
        dispatch('close');
    };
</script>
<div class="overflow-y-auto max-h-[560px] text-sm" use:focusFirstElement>
    <div class="border border-zinc-300 mt-2 font-thin">
        <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
             on:click={toggle('generic')}>
            <button class="plain">
                {#if opened['generic']}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            Γενικά
        </div>

        <div class:hidden={!opened['generic']} class="p-sm bg-zinc-50 shadow-inner">
            <div class="mb-2 text-sm">
                    <div class="font-thin text-sm">Παραλήπτης εγγράφου</div>
                    <textarea class="text-xs h-32" bind:value={tae.police}/>
                </div>
            <PairEditor full title="Τρόπος προμήθειας.">
                <input type="text"
                       bind:value={tae.merchant}
                       use:trapKey={{callback: saveTae}}>
            </PairEditor>
            <div class="font-thin text-sm mt-2">
                <div>Ζώα:</div>
                {#each tae.animals as animal}
                    <div class="pl-sm grid grid-cols-2 gap-sm items-center">
                        <div>
                            <input type="text" bind:value={animal.name}>
                        </div>
                        <div>
                            <input type="text" bind:value={animal.count}>
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>


    <div class="border border-zinc-300 mt-2 font-thin">
        <div class="p-1 bg-white cursor-pointer flex gap-sm items-center text-lg"
             on:click={toggle('contacts')}>
            <button class="plain">
                {#if opened['contacts']}
                    <Icon icon="arrow-up-drop-circle-outline"/>
                {:else}
                    <Icon icon="arrow-down-drop-circle-outline"/>
                {/if}
            </button>
            Συμβαλλόμενοι
        </div>

        <div class:hidden={!opened['contacts']} class="p-sm bg-zinc-50 shadow-inner">
            {#each tae.contacts as contact, idx (idx)}
                <div class:text-gray-light={!contact.selected}
                     class="bg-white flex items-start gap-sm px-sm py-1 mb-2">
                    <div class=pt-1>
                        {#if contact.selected}
                            <button class="plain flex-1" title="Αφαίρεση στους συμβαλλόμενους"
                                    on:click={removeContact(idx)}>
                                <Icon icon="check-circle" color="009900"/>

                            </button>
                        {:else}
                            <button class="plain flex-1" title="Προσθήκη στους συμβαλλόμενους"
                                    on:click={unremoveContact(idx)}>
                                <Icon icon="check-circle" color="aaaaaa"/>
                            </button>
                        {/if}
                    </div>

                    <div class="flex-1">
                        <PairEditor title="Όνομα">
                            <input type="text" bind:value={contact.name}>
                        </PairEditor>
                        {#if contact.selected}
                            <PairEditor title="Α.Δ.Τ.">
                                <input type="text" bind:value={contact.adt}>
                            </PairEditor>

                            <PairEditor title="Ημ/νία γέννησης">
                                <input type="text" bind:value={contact.dob}>
                            </PairEditor>

                            <PairEditor title="Επάγγελμα">
                                <input type="text" bind:value={contact.job}>
                            </PairEditor>
                            <PairEditor title="Διεύθυνση">
                                <input type="text" bind:value={contact.address}>
                            </PairEditor>
                        {/if}
                    </div>
                </div>
            {/each}
        </div>
    </div>
</div>
<div class="text-right p-1">
    <span class="link" on:click={toggleAll}>Κλείσε όλα</span> |
    <span class="link" on:click={untoggleAll}>Ανοιξε όλα</span>
</div>
<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={() => dispatch('close')}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveTae}>Αποθήκευση</button>
</div>
