<script>
    import {createEventDispatcher, onMount} from "svelte";
    import CloseButton from "./layout/CloseButton.svelte";
    import {focusFirstElement, trapKey} from "../lib/actions.js";

    let className = '';
    export {className as class};
    export let title = 'title';
    export let closesOnEscape = false;
    export let showClose = false;


    let open = false;
    let popup;

    const dispatch = createEventDispatcher();

    export const close = () => {
        if (closesOnEscape === false) {
            return;
        }
        open = false;
        setTimeout(() => {
            dispatch('close');
        }, 200);
    };

    onMount(async () => {
        setTimeout(() => {
            open = true;
        }, 0);
    });
</script>

<div class="fixed inset-0 bg-black bg-opacity-25 z-30">
    <div bind:this={popup} class="popup" class:open use:trapKey={{key:'Escape', callback:close}}>
        <div class="border-orange border-solid border-2 bg-bg drop-shadow-xl rounded-lg overflow-hidden">
            <div class="px-xs sm:px-md py-1 bg-orange text-white text-lg font-thin flex justify-between">
                {title}
                {#if showClose}
                    <CloseButton on:click={close}/>
                {/if}
            </div>
            <div class="p-md max-h-[90vh] overflow-y-auto {className}" use:focusFirstElement>
                <slot/>
            </div>
        </div>
    </div>
</div>
<style>
    .popup {
        @apply w-full min-h-full md:max-w-xl fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
