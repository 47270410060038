<script>
    import {API, dDMYhm, EVENT_STATUS} from "../../../lib/lib.js";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import PreContractData from "../../papers/PreContractData.svelte";

    export let event;
    export let venue;

    let preContractPopupVisible = false;

    const showPreContractPopup = () => preContractPopupVisible = true;

    const hidePreContractPopup = () => preContractPopupVisible = false;

    const toggleAndPrintPreContract = async e => {
        await togglePreContractPrint(true);
        window.open(`/print/${event.id}/preContract`);
    };

    const togglePreContractPrint = async e => {
        if (!confirm("Το προσχέδιο θα αλλάξει κατάσταση εκτύπωσης.\n\nΕίσαστε σίγουροι;")) {
            return;
        }

        let toSend = {};
        if (e === true) {
            toSend = {state: true}
        }

        const res = await API.patch(`event/${event.id}/preContract`, toSend);
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.pre_contract.printed_at = res.data.printed_at;

        showMessage(`Η κατάσταση άλλαξε σε εκτυπωμένο`);
    };

    const savePreContactData = async ({detail: data}) => {
        const res = await API.post(`event/${event.id}/preContract`, {data});
        if (res.error) {
            showMessage('Κάτι δεν πήγε καλά', 'bg-red');
            return;
        }

        event.pre_contract = res.data;
        showMessage('Το προσχέδιο αποθηκευτηκε');
        hidePreContractPopup();
    };
</script>

<div class="mb-2">
    <div class="font-bold">

        {#if event.status != EVENT_STATUS.EVENT_STATUS_CANCELED}
            <span class="link underline" on:click={showPreContractPopup}>Προσχέδιο</span>
        {:else}
            Προσχέδιο
        {/if}
    </div>
    <div class="p-1 mb-1">
        {#if event.pre_contract?.printed_at}
            <span class="text-green">Εκτυπώθηκε: {dDMYhm(event.pre_contract.printed_at)}</span>
            <span class="link" on:click={toggleAndPrintPreContract}>Εκτύπωση</span>
        {:else}
            {#if event.pre_contract == null}
                <span class="text-red">Χωρίς Προσχέδιο.</span>
            {:else}
                Δεν εχει εκτυπωθεί το Προσχέδιο.
                <span class="link" on:click={toggleAndPrintPreContract}>Εκτύπωση</span>
            {/if}
        {/if}


    </div>
</div>


{#if preContractPopupVisible}
    <ScreenCenter title="Εκτύπωση προσχεδίου" on:close={hidePreContractPopup}>
        <PreContractData
                {venue}
                contract={event.pre_contract}
                contacts={event.contacts}
                on:close={hidePreContractPopup}
                on:save={savePreContactData}
        />
    </ScreenCenter>
{/if}
