<script>
    import {API, serviceCategories$} from "../../lib/lib.js";
    import ErrorDisplay from "../layout/ErrorDisplay.svelte";
    import {focusFirstElement, trapKey} from "../../lib/actions.js";
    import {createEventDispatcher} from "svelte";
    import Spacer from "../layout/Spacer.svelte";
    import PublishedState from "../varius/PublishedState.svelte";
    import NoteItem from "../Ev/qv/NoteItem.svelte";
    import IconButton from "../layout/IconButton.svelte";
    import TextItem from "../varius/TextItem.svelte";

    export let serviceCategory;

    if (!serviceCategory) {
        serviceCategory = {
            notes:[],
            texts:[],
            in_menu: false,
            is_active: false,
        };
    }

    const dispatch = createEventDispatcher();

    let errors = null;

    let serviceCategoryCopy = {
        name: serviceCategory.name || '',
        in_menu: serviceCategory.in_menu,
        is_active: serviceCategory.is_active,
        notes: serviceCategory.notes,
        texts: serviceCategory.texts
    };

    const resetServiceCategoryForm = () => {
        serviceCategoryCopy = {
            name: '',
            in_menu: false,
            is_active: true,
            notes: [],
            texts: [],
        }
    };

    const saveServiceCategory = () => {
        errors = null;

        if (serviceCategory.id) {
            updateServiceCategory();
        } else {
            createNewServiceCategory();
        }
    };

    const createNewServiceCategory = async () => {
        const res = await API.post(`serviceCategory`, serviceCategoryCopy);
        if (res.error == true) {
            errors = res.data;
            return;
        }

        serviceCategories$.update(store => {
            return [...store, res.data];
        });

        resetServiceCategoryForm();

        showMessage('Η κατηγορία δημιουργήθηκε');
        dispatch('save', res.data);
    };

    const updateServiceCategory = async () => {
        const res = await API.patch(`serviceCategory/${serviceCategory.id}`, serviceCategoryCopy);
        if (res.error == true) {
            errors = res.data;
            return;
        }

        serviceCategories$.update(store => {
            return store.map(v => {
                if (v.id == serviceCategory.id) {
                    return Object.assign(v, serviceCategoryCopy)
                }
                return v;
            });
        });

        showMessage('Η κατηγορία αποθηκεύτηκε');
        dispatch('save', res.data);
    };

    const close = () => dispatch('close');

    const addText = () => {
        serviceCategoryCopy.texts = [...serviceCategoryCopy.texts, {id: 0, body: ''}];
    };

    const redraw = () => {
        serviceCategoryCopy = serviceCategoryCopy;
    };
</script>

<div use:focusFirstElement>
    <h4>Όνομα</h4>
    <input type="text"
           bind:value={serviceCategoryCopy.name}
           use:trapKey={{callback: saveServiceCategory}}
    >
    <Spacer/>

    <PublishedState bind:value={serviceCategoryCopy.in_menu} label="Να εμφανίζεται στο έντυπο κουζίνας"/>

    <PublishedState bind:value={serviceCategoryCopy.is_active}/>

    {#if serviceCategory.id}
        <Spacer/>
        <h4>
            <span class="float-right">
                <IconButton title="Προσθήκη κειμένου" color="00aa00" button="plus-circle" on:click={addText}/>
            </span>
            Εξτρα κείμενα
        </h4>

        {#each (serviceCategoryCopy.texts || []) as text (text.id)}
            <TextItem
                    endpoint='serviceCategory'
                    on:delete={redraw}
                    {text}
                    entity={serviceCategory}
            />
        {/each}
    {/if}
</div>

<ErrorDisplay bind:errors/>


<hr>
<div class="text-right">
    <button class="btn btn-link underline" on:click={close}>Κλείσιμο</button>
    <button class="btn btn-ok" on:click={saveServiceCategory}>Αποθήκευση</button>
</div>


