<script>
    import SidebarItem from "./SidebarItem.svelte";
    import {fade} from "svelte/transition";
    import AutoComplete from 'simple-svelte-autocomplete';
    import {contacts$} from "../../lib/lib.js";
    import {router} from "../../lib/Router/Router.svelte";
    import {showContactsPanel} from '../contacts/ContactsPanel.svelte';
    import Icon from "./Icon.svelte";

    let hidden = true;
    const toggleMenu = () => hidden = !hidden;

    let names = [];

    let selectedContact = null;


    $: names = $contacts$.map(i => {
        return {
            id: i.id,
            name: i.fullname,
            search: [i.__search, ...i.notes.map(n => n.body)].join('|')
        };
    });

    const showContacts = () => showContactsPanel(c => {
        router.goNow(`/app/contact/${c.id}`);
    });

    $: if (selectedContact) {
        router.goNow(`/app/contact/${selectedContact.id}`);
    }
</script>

<button
        class="md:hidden absolute right-1 top-1 bg-black z-30 p-2"
        on:click={toggleMenu}>
    <Icon icon={hidden ? 'menu': 'menu-open'} color="ffffff"/>
</button>

{#if !hidden}
    <div class="absolute inset-0 bg-black z-20 opacity-25 moves md:hidden" transition:fade></div>
{/if}

<div class="absolute h-full bg-zinc-600 overflow-hidden md:block w-full max-w-[240px] z-20 moves" class:rolled={hidden}>
    <SidebarItem on:click={toggleMenu} url="">Dashboard</SidebarItem>
    <SidebarItem on:click={toggleMenu} url="events">Εκδηλώσεις</SidebarItem>
    <SidebarItem on:click={toggleMenu} url="contact">Επαφές</SidebarItem>
    <SidebarItem on:click={toggleMenu} url="settings">Ρυθμίσεις</SidebarItem>

    <div class="mt-xl p-2 text-sm">
        <div class="text-white pb-1">Αναζήτηση...</div>
        <AutoComplete
                className="w-full"
                inputClassName="text-xs"
                hideArrow
                showClear
                noResultsText="Δεν βρέθηκε τίποτα σε όνομα - τηλέφωνα - email - σημειώσεις"
                items={names}
                labelFieldName="name"
                valueFieldName="id"
                keywordsFunction={c => c.search}
                bind:selectedItem={selectedContact}
        />
    </div>
</div>


<style>
    .rolled {
        @apply md:translate-x-0 -translate-x-full ;
    }
</style>