<script>
    export let title = '';
    export let full = false;
    export let span = null;

    const cols = span ? Number(span)+1 : 2;

    const cls = full ? '' : `grid grid-cols-${cols} items-center`;
    const cls1 = (span && full == false) ? `col-span-${span}` : '';
</script>


<div class="{cls} text-sm mb-2">
    <div class="font-thin pb-[2px]">
        {title}
    </div>
    <div class:pl-1={full} class={cls1}>
        <slot/>
    </div>
</div>
<div class="col-span-2"></div>