<script>
    import ScreenCenter from "../ScreenCenter.svelte";
</script>

<ScreenCenter title="Login">
    <form method="post" action="/login">
        <div class="flex justify-between items-center my-sm">
            <div> Email</div>
            <div>
                <input type="email" name="email" value="">
            </div>
        </div>
        <div class="flex justify-between items-center my-sm">
            <div> Password</div>
            <div>
                <input type="password" name="password" value="">
            </div>
        </div>

        <hr>

        <div>
            <input type="submit" class="btn block w-full" value="Login">
        </div>
    </form>
</ScreenCenter>