<script>
    import {createEventDispatcher} from "svelte";
    import IconButton from "../layout/IconButton.svelte";

    export let contact;
    const dispatch = createEventDispatcher();
    const edit = event => dispatch('edit', contact);
    const select = event => dispatch('select', contact);
</script>


<div class="flex py-sm text-sm px-xs border-b border-zinc-200">
    <button class="block w-full text-left" on:click={select}>{contact.first_name} { contact.last_name }</button>
    <IconButton
                    title="Επεξεργασία"
                    color="00aa00"
                    button="pencil-circle"
                    on:click={edit}/>
</div>