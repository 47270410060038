<script>
    import {router} from "../../lib/Router/Router.svelte";
    import A from "../../lib/Router/A.svelte";
    import Icon from "../layout/Icon.svelte";

    let selected;

    let visible = false;

    const toggle = () => visible = !visible;

    const menu = [
        [true, 'venues', 'Εγκαταστάσεις'],
        [true, 'eventTypes', 'Τύποι εκδηλώσεων'],
        [true, 'services', 'Παροχές'],
        [true, 'extra', 'Υπηρεσίες'],
        [true, 'billables', 'Χρεώσεις'],
        [true, 'terms', 'Όροι'],
        [false, 'rooms', 'Δωμάτια'],
    ];

    $: {
        visible = false;
        selected = {
            'venues': 'Εγκαταστάσεις',
            'eventTypes': 'Τύποι εκδηλώσεων',
            'extra': 'Υπηρεσίες',
            'services': 'Παροχές',
            'billables': 'Χρεώσεις',
            'rooms': 'Χώροι',
            'terms': 'Όροι',
        }[$router.parts[3]] ?? null;
    }
</script>

<div class="relative md:static">
    <button class="md:hidden flex gap-sm items-center plain p-sm" on:click={toggle}>
        <Icon icon="arrow-down-drop-circle-outline"/>
        {selected}
    </button>

    <div class="fixed md:block md:static  inset-0 bg-white" class:hidden={!visible}>
        <div class="md:pl-sm">
            {#each menu as item, idx}
                {#if item[0]}
                    <A activeClass="md:!border-b-orange md:!text-white md:!bg-orange !bg-yellow text-base" class="tab"
                       useExactActive
                       href="/app/settings/{item[1]}">
                        <Icon class="md:hidden {idx && 'opacity-0'}" icon="arrow-up-drop-circle-outline"/>
                        {item[2]}
                    </A>
                {/if}
            {/each}
        </div>
    </div>
</div>

<style>
    :global(.tab) {
        @apply border-b py-xs px-xs text-black flex gap-sm text-sm;
        @apply md:rounded-t-lg md:px-lg md:inline-block md:py-xs md:mt-2 md:bg-gray-light md:border md:border-b-0 md:border-zinc-400 md:mr-0.5;
        @apply hover:bg-orange hover:text-white ;
    }
</style>