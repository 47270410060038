<script>
    import {API, dDMYhm, EVENT_STATUS} from "../../../lib/lib.js";
    import ScreenCenter from "../../ScreenCenter.svelte";
    import EventServicesData from "../../papers/EventServicesData.svelte";

    export let event;
    export let room;

    let popupVisible = false;

    const showEventServicesPopup = () => popupVisible = true;

    const hideEventServicesPopup = () => popupVisible = false;
</script>

<div class="mb-2">
    <div class="font-bold">
        {#if event.status != EVENT_STATUS.EVENT_STATUS_CANCELED}
            <span class="underline link" on:click={showEventServicesPopup}>Παροχές</span>
        {:else }
            Παροχές
        {/if}
    </div>
    <div class="p-1 mb-1">
        {#if event.services}
            <span class="text-green">Έχουν οριστεί οι παροχές</span>
        {:else}
            <span class="text-red">Δεν έχουν οριστεί παροχές</span>
        {/if}


    </div>
</div>


{#if popupVisible}
    <ScreenCenter title="Ορισμός παροχών" on:close={hideEventServicesPopup}>
        <EventServicesData
                {room}
                {event}
                on:close={hideEventServicesPopup}
        />
    </ScreenCenter>
{/if}
