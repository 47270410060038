<script>

    import {API, serviceCategories$} from "../../lib/lib.js";
    import ScreenCenter from "../ScreenCenter.svelte";
    import ServiceCategoryEditor from "./ServiceCategoryEditor.svelte";
    import ServiceEditor from "./services/ServiceEditor.svelte";
    import ServiceCategoryItem from "./ServiceCategoryItem.svelte";

    let addServiceCategoryVisible = false;
    let addServicePopupVisible = false;
    let serviceCategory = null;
    let service = null;

    let expanded = $serviceCategories$.map(i => false);

    const closeAll = () => expanded = $serviceCategories$.map(i => false);
    const openAll = () => expanded = $serviceCategories$.map(i => true);

    const showAddServiceCategoryDialog = ({detail: category = null}) => {
        addServiceCategoryVisible = true;
        serviceCategory = category;
    };

    const hideAddServiceCategoryDialog = () => {
        addServiceCategoryVisible = false;
        serviceCategory = null;
    };

    const showAddServiceDialog = ({detail: category = null}, item = null) => {
        serviceCategory = category;
        service = item;
        addServicePopupVisible = true;
    };

    const hideAddServiceDialog = () => {
        serviceCategory = null;
        addServicePopupVisible = false;
    };

    const showEditService = async ({detail: item}) => {
        serviceCategory = item.category;
        service = item.service;
        addServicePopupVisible = true;
    };

    const deleteCategory = async ({detail: category}) => {
        if (!confirm('Είσαστε σίγουροι;')) {
            return;
        }
        const res = API.delete(`serviceCategory/${category.id}`);

        if (res.error) {
            showMessage('Κάποιο λάθος συνέβη', 'bg-red');
            return;
        }

        serviceCategories$.update(s => {
            return s.filter(item => item.id != category.id);
        });

        showMessage('Η Κατηγορία διαγράφηκε');
    };

    const deleteService = async ({detail: service}) => {
        if (!confirm('Είσαστε σίγουροι;')) {
            return;
        }
        const res = API.delete(`service/${service.id}`);

        if (res.error) {
            showMessage('Κάποιο λάθος συνέβη', 'bg-red');
            return;
        }

        serviceCategories$.update(store => {
            store.map(category => {
                if (category.id == service.service_category_id) {
                    category.services = category.services.filter(theService => {
                        return theService.id != service.id;
                    });
                }
            });
            return store;
        });

        showMessage('Το προϊόν διαγράφηκε');
    }
</script>


<div class="main-place py-0">
    <div class="fixed py-1 px-sm bg-white w-full flex items-center border-b border-zinc-200">
        <button on:click={() => showAddServiceCategoryDialog({})} class="btn border">
            Νέα Κατηγορία
        </button>
        <div class="pl-xl">
            <button class="link underline" on:click={closeAll}>Κλείσε όλα</button>
            <button class="link underline" on:click={openAll}>Ανοιξε όλα</button>
        </div>


    </div>
    <div class="pt-xl">
        <!--{#key expanded}-->
        {#each $serviceCategories$ as category (category.id)}
            <ServiceCategoryItem
                    on:delete={deleteCategory}
                    on:edit={showAddServiceCategoryDialog}
                    on:addService={showAddServiceDialog}
                    on:editService={showEditService}
                    on:deleteService={deleteService}
                    expanded={expanded[category.id]}
                    {category}
            />
        {/each}
        <!--{/key}-->
    </div>
</div>
{#if addServiceCategoryVisible}
    <ScreenCenter on:close={hideAddServiceCategoryDialog}
                  title="{serviceCategory?.id ? 'Επεξεργασία':'Προσθήκη'} κατηγορίας">
        <ServiceCategoryEditor
                {serviceCategory}
                on:save={hideAddServiceCategoryDialog}
                on:close={hideAddServiceCategoryDialog}/>
    </ScreenCenter>
{/if}

{#if addServicePopupVisible}
    <ScreenCenter on:close={hideAddServiceDialog} title="{service?.id ? 'Επεξεργασία':'Προσθήκη'} προϊόντος/υπηρεσίας">
        <ServiceEditor
                {service}
                {serviceCategory}
                on:close={hideAddServiceDialog}
                on:save={hideAddServiceDialog}/>
    </ScreenCenter>
{/if}


<!--
<Section foldable
                 label="{serviceCategory.name} ({serviceCategory.services.length} services)"
        >
            <svelte:fragment slot="buttons">
                <SquareButton button='edit' on:click={() => showAddServiceCategoryDialog(serviceCategory)}/>
                <IconButton button='plus' on:click={() => showAddServiceDialog(serviceCategory)}>Νέο προϊόν</IconButton>
            </svelte:fragment>
            {#if serviceCategory.services.length}
                {#each serviceCategory.services as service}
                    <Hoverable class="flex justify-between items-center">
                        {service.name}
                        <SquareButton
                                title="Επεξεργασία"
                                on:click={() => showAddServiceDialog(serviceCategory, service)}/>
                    </Hoverable>
                {/each}
            {/if}
        </Section>
-->